<template>
  <div>
    <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
      style="margin-bottom: 16px !important;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>

    <div
      :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? 'padding: 0px 38px 0px 38px;' : 'padding: 38px;'"> -->
      <v-card-text
        class="black--text py-2">
        <v-row>
          <v-col
            cols="12"
            md="7">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Nomor
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Lampiran
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Hal
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div
          class="mt-6"
          style="margin-top: 24px !important;">
          <div
            style="margin-bottom: 1px;">
            Kepada Yth.
          </div>

          <div
            v-for="(item, index) in selected.preview.template_meta_json_object.kepada"
            :key="index"
            class="d-flex align-center"
            style="margin-bottom: 1px; display: flex !important; align-items: center !important;">
            <span>
              {{ index + 1 }}.
            </span>

            <span
              class="mx-2"
              style="margin-right: 8px !important; margin-left: 8px !important;">
              {{ item.nama !== '' ? item.nama : '__________' }}
            </span>

            <span>
              ({{ item.unit !== '' ? item.unit : '__________' }})
            </span>
          </div>

          <div
            style="margin-bottom: 1px;">
            di lingkungan PT. Umat Mandiri Berkemajuan
          </div>
        </div>

        <div
          class="mt-10"
          style="margin-top: 40px !important;">
          <v-img
            contain
            width="175"
            height="auto"
            alt="umb"
            :src="require('@/assets/image/assalam.jpeg')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="my-4"
            style="margin-top: 16px !important; margin-bottom: 16px !important;">
            <div
              class="mb-4"
              style="text-indent: 30px; margin-bottom: 16px !important;">
              Dengan hormat, sehubungan dengan memasukinya {{ selected.preview.template_meta_json_object.hari_libur !== '' ? selected.preview.template_meta_json_object.hari_libur : '__________' }}, maka bersama surat ini kami sampaikan informasi sebagai berikut :
            </div>

            <div
              class="mb-4"
              style="margin-left: 22px; margin-bottom: 16px !important;">
              <ol
                type="a">
                <li
                  class="pl-2"
                  style="padding-left: 8px !important;">
                  Libur menjelang {{ selected.preview.template_meta_json_object.hari_libur !== '' ? selected.preview.template_meta_json_object.hari_libur : '__________' }} dimulai pada tanggal
                  <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
                    {{ selected.preview.template_meta_json_object.tanggal_mulai | day }}
                  </span>

                  <span v-else>
                    __________
                  </span>

                  -

                  <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
                    {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }}
                  </span>

                  <span v-else>
                    __________
                  </span>
                </li>

                <li
                  class="pl-2"
                  style="padding-left: 8px !important;">
                  Kegiatan dan aktifitas di lingkungan PT. UMB mulai aktif kembali pada tanggal
                  <span v-if="selected.preview.template_meta_json_object.tanggal_masuk !== '' && selected.preview.template_meta_json_object.tanggal_masuk !== null">
                    {{ selected.preview.template_meta_json_object.tanggal_masuk | fulldate }}
                  </span>

                  <span v-else>
                    __________
                  </span>
                </li>

                <li
                  class="pl-2"
                  style="padding-left: 8px !important;">
                  Informasi atau kebijakan lain {{ selected.preview.template_meta_json_object.informasi_lain !== '' ? selected.preview.template_meta_json_object.informasi_lain : '__________' }}.
                </li>
              </ol>
            </div>

            <div
              class="mb-4"
              style="text-indent: 30px; margin-bottom: 16px !important;">
              Selanjutnya, mohon bagi pimpinan unit bisnis PT. UMB untuk menyampaikan infromasi tersebut di unit bisnis masing-masing.
            </div>

            <div
              style="text-indent: 30px;">
              Demikian pemberitahuan ini kami sampaikan atas perhatian dan kerjasamanya diucapkan terima kasih.
            </div>
          </div>

          <v-img
            contain
            width="175"
            height="auto"
            alt="umb"
            :src="require('@/assets/image/wassalam.jpeg')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>

        <v-row
          justify="end"
          class="mt-8"
          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: center !important;">
            <div>
              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
            </div>

            <div
              class="mb-16"
              style="margin-bottom: 64px !important;">
              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
            </div>

            <div>
              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    <!-- </div>

    <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
      style="margin-top: 16px !important;">
      <v-img
        style="position: absolute; bottom: 0;"
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image_footer">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      // units: {
      //   type: Array,
      //   default: []
      // },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        picker: {
          expired: false,
          tanggal: false,
          tanggal_mulai: false,
          tanggal_selesai: false,
          tanggal_masuk: false,

          range: false
        },

        time: {
          pukul: false
        },
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      user () {
        return this.$store.state.user
      },

      set_color () {
        return this.$store.state.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },

      configHeaders () {
        return this.headers.filter(header => !header.hide)
      },

      // formattedDate: {
      //   get () {
      //     return (this.form.template_meta_json_object.tanggal && this.form.template_meta_json_object.tanggal.length === 10) ? moment(this.form.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.form.template_meta_json_object.tanggal
      //   },
      //   set (newValue) {
      //     if (newValue && newValue.length == 10) {
      //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
      //         this.form.template_meta_json_object.tanggal = newDate
      //     }
      //   }
      // },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
