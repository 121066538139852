<template>
  <div class="fill-height pb-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }} Berhasil
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah melakukan presensi {{ form.type }}.
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.success = false"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.form"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }}
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Apakah Anda yakin akan melakukan presensi {{ form.type }}?
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.form = false; dialog.option = true;"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn 
                @click="dialog.form = false"
                color="red"
                outlined
                elevation="0"
                class="font-weight-bold text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.option"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }}
              </span>

              <!-- <v-spacer/>

              <v-icon 
                @click="dialog.option = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon> -->
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3 pb-2">
              <v-row 
                align="center" 
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div 
                    v-on:keyup.enter="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                    @click="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                    class="cursor-pointer">
                    <v-avatar 
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="amanata" 
                        :src="require('@/assets/image/camera.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Kamera
                    </div>
                  </div>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center px-0">
                  <div 
                    class="d-flex align-center">
                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div 
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <div 
                    v-on:keyup.enter="saveCheckInOut()"
                    @click="saveCheckInOut()"
                    class="cursor-pointer">
                    <v-avatar 
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="amanata" 
                        :src="require('@/assets/image/camera_block.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Lewati
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <div v-if="message.error.length > 0"
              class="text-center ma-4">
              <div
                class="error--text d-flex align-start">
                <v-icon size="18" color="red" class="mt-1">mdi-alert-circle-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ message.error }}
                </span>
              </div>
            </div>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <!-- <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="saveCheckInOut()"
                @click="saveCheckInOut()"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                @click="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn> -->

              <v-btn 
                @click="dialog.option = false"
                :disabled="process.form"
                color="red"
                outlined
                elevation="0"
                class="font-weight-bold text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.permission"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Permission Telah di block
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Silahkan aktifkan permission lokasi di perangkat Anda terlebih dahulu.
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.permission = false"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.alasan"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Alasan
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              <span v-if="detail.status === 'ijin'">
                Anda hari ini sedang ijin{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
              </span>

              <span v-if="detail.status === 'leave'">
                Anda hari ini sedang meinggalkan jam kerja{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
              </span>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.alasan = false"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            height="175"
            :color="set_color"
            class="mb-16"
            style="border-radius: 0px;">
            <v-card-text
              class="pt-16 mt-6">
              <v-card
                color="white"
                style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; border-radius: 10px; z-index: 1;">
                <v-list>
                  <v-skeleton-loader
                    :loading="Object.keys(company).length < 1"
                    type="list-item-avatar-two-line">
                    <div>
                      <v-list-item v-if="Object.keys(company).length > 0">
                        <!-- <v-list-item-avatar
                          size="50"
                          style="border: 2px solid #e7e9ec;">
                          <v-img 
                            contain
                            width="100%"
                            height="100%"
                            alt="amanata" 
                            :src="company.logo === '' ? require('@/assets/logo/favicon.png') : company.logo"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar> -->

                        <v-list-item-avatar
                          size="50"
                          color="white">
                          <v-avatar
                            size="50"
                            style="border: 2px solid #e7e9ec;">
                            <v-img 
                              contain
                              width="100%"
                              height="100%"
                              alt="amanata" 
                              :src="company.logo === '' ? require('@/assets/logo/favicon.png') : company.logo"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold black--text mb-2">
                            {{ company.company_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 text-capitalize">
                            Unit {{ user.unit_name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action
                          class="mt-0 mb-5">
                          <v-icon size="26" color="black">mdi-chevron-down</v-icon>
                        </v-list-item-action> -->
                      </v-list-item>
                    </div>
                  </v-skeleton-loader>
                </v-list>

                <v-divider
                  class="mx-4 my-1"
                  style="border-top: 1px solid #e0e0e0;" />

                <v-card-text
                  class="pb-0">
                  <div
                    class="d-flex align-center">
                    <v-avatar
                      size="24"
                      tile>
                      <v-img 
                        contain
                        alt="amanata" 
                        :src="require('@/assets/icon/calendar.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 ml-2">
                      {{ datenow | weekday }}, {{ datenow | date }} 
                    </span>
                  </div>

                  <div v-if="!process.attendance && detail.status !== 'ijin' && detail.status !== 'leave'"
                    class="body-2 mt-2">
                    <span v-if="Object.keys(detail).length < 1"
                      class="red--text">
                      Anda belum melakukan presensi hari ini
                    </span>

                    <span v-else>
                      <span v-if="!detail.end_bool"
                        class="orange--text">
                        Anda belum melakukan presensi pulang
                      </span>

                      <span v-else
                        class="green--text">
                        Anda sudah melakukan presensi hari ini
                      </span>
                    </span>
                  </div>

                  <div v-if="!process.attendance && detail.status === 'leave'"
                    @click="dialog.alasan = true"
                    class="body-2 mt-2 blue--text one-line cursor-pointer">
                    Anda hari ini sedang meinggalkan jam kerja{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
                  </div>

                  <div v-if="!process.attendance && detail.status === 'ijin'"
                    @click="dialog.alasan = true"
                    class="body-2 mt-2 blue--text one-line cursor-pointer">
                    Anda hari ini sedang ijin{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
                  </div>
                </v-card-text>

                <v-card-actions
                  class="px-4">
                  <v-row>
                    <v-col 
                      cols="5">
                      <v-btn
                        @click="reset('datang')"
                        :disabled="Object.keys(detail).length > 0 && !detail.end_bool"
                        block
                        elevation="0"
                        :color="set_color"
                        class="body-2 text-capitalize font-weight-bold white--text"
                        style="border-radius: 8px;">
                        Datang
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="5">
                      <v-btn
                        @click="reset('pulang')"
                        :disabled="Object.keys(detail).length < 1 || detail.end_bool"
                        block
                        elevation="0"
                        color="#E62129"
                        class="body-2 text-capitalize font-weight-bold white--text"
                        style="border-radius: 8px;">
                        Pulang
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="2">
                      <v-btn
                        to="/attendance"
                        elevation="0"
                        outlined
                        color="#e0e0e0"
                        min-width="36"
                        class="pa-0"
                        style="border-radius: 8px;">
                        <v-avatar
                          size="18"
                          tile>
                          <v-img 
                            contain
                            alt="amanata" 
                            :src="require('@/assets/icon/file.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="15"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>

          <v-card
            flat
            class="pt-12"
            color="transparent">
            <v-card-text
              :class="!popup ? 'pb-2' : ''">
              <div
                class="body-1 font-weight-bold black--text mt-6"
                :class="!popup ? '' : 'mb-4'">
                Laporan Harian
              </div>

              <v-card v-if="popup"
                flat
                :color="list.report.length < 1 ? '#FBC460' : '#348d37'"
                style="border-radius: 10px;">
                <v-card-text
                  class="body-2 line-text-second pb-0"
                  :class="list.report.length < 1 ? '' : 'white--text'">
                  <span v-if="list.report.length < 1">
                    Anda belum mengirimkan laporan harian. Silahkan klik tombol dibawah untuk membuat laporan.
                  </span>

                  <span v-else>
                    Berikut adalah laporan harian yang Anda kirim, terima kasih Anda telah melakukan kegiatan hari ini.
                  </span>
                </v-card-text>

                <v-card-actions
                  class="pa-4 pt-0">
                  <v-spacer />

                  <v-btn
                    @click="closePopup()"
                    small
                    color="#FFFFFF40"
                    elevation="0"
                    class="border-radius white--text text-capitalize">
                    Tutup
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>

            <v-card-actions
              class="px-4">
              <v-btn
                @click="sheet.add_report = true; reset_report();"
                block
                elevation="0"
                outlined
                large
                color="#e0e0e0"
                class="body-1 font-weight-bold text-capitalize d-flex align-center"
                style="border-radius: 8px;">
                <v-avatar
                  size="18"
                  tile>
                  <v-img 
                    contain
                    alt="amanata" 
                    :src="require('@/assets/icon/add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="15"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                
                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Buat Laporan Harian
                </span>
              </v-btn>
            </v-card-actions>

            <v-card-text>
              <v-text-field v-if="list.report.length > 0 || search !== ''"
                v-model="search"
                @click:clear="search = ''; fetchReport();"
                v-on:keyup.enter="fetchReport()"
                class="no_box mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.report ? 3 : list.report"
                    :key="index"
                    :loading="process.report"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.report && list.report.length > 0"
                          @click="selected.report = item; sheet.detail_report = true;"
                          :class="index < list.report.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 10px;
                                background: #FFFFFF40;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="set_color"
                                x-small>
                                {{ item.category_title }}
                              </v-chip>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <div v-if="pagination.report.total_page > 1"
                class="d-flex align-center mt-4">
                <v-spacer />

                <v-btn
                  to="/report"
                  small
                  color="orange"
                  elevation="0"
                  class="text-capitalize white--text"
                  style="border-radius: 8px;">
                  Lihat Semua Laporan

                  <v-icon small class="ml-1">mdi-chevron-double-right</v-icon>
                </v-btn>

                <v-spacer />
              </div>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.add_report = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Tambah Laporan
              </span>
            </v-card-title>

            <v-card-text 
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="title" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Judul
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="saveReport"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. Besok Rapat"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="category_id" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kategori
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save"
                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. Aktifitas"
                        
                        :items="category"
                        item-text="title"
                        item-value="id"
                        :item-color="set_color"

                        @change="form.nominal = 0"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.category_id"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider v-if="form.category_id == 7"
                      name="nominal" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nominal
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-currency-field
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        :min="0"

                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 1.000.000"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        
                        v-model="form.nominal"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-currency-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Pelaksanaan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="pelaksanaandate"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.pelaksanaan">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="pelaksanaan_date" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              class="no_box"
                              solo
                              :color="set_color"
                              placeholder="Contoh. 2022-12-30"
                              
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.pelaksanaan_date = ''"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.pelaksanaan_date"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          :color="set_color"
                          v-model="form.pelaksanaan_date"
                          @input="picker.pelaksanaan = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Waktu Pelaksanaan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="pelaksanaantime"
                        v-model="time.pelaksanaan"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.pelaksanaan_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Waktu Pelaksanaan" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              class="no_box"
                              solo
                              :color="set_color"
                              placeholder="Contoh. 09:00"

                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.pelaksanaan_time = ''; form.selesai_time = '';"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.pelaksanaan_time"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-time-picker
                          v-if="time.pelaksanaan"
                          v-model="form.pelaksanaan_time"
                          full-width
                          :color="set_color"
                          format="24hr"
                          @click:minute="$refs.pelaksanaantime.save(`${form.pelaksanaan_time}:00`); form.selesai_time = '';">
                        </v-time-picker>
                      </v-menu>
                    </div>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Waktu Selesai
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="selesaitime"
                        v-model="time.selesai"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.selesai_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Waktu Selesai" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              readonly
                              class="no_box"
                              solo
                              :color="set_color"
                              placeholder="Contoh. 12:00"

                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.selesai_time = ''"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.selesai_time"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-time-picker
                          v-if="time.selesai"
                          v-model="form.selesai_time"
                          :min="form.pelaksanaan_time"
                          full-width
                          :color="set_color"
                          format="24hr"
                          @click:minute="$refs.selesaitime.save(`${form.selesai_time}:00`)">
                        </v-time-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider 
                      name="desc" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="saveReport"
                        class="no_box"
                        solo
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.desc"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>

                    <div>
                      <div class="body-2">
                        <span v-if="form.category_id == 2 || form.category_id == 5 || form.category_id == 7 || form.category_id == 8"
                          class="red--text">
                          *
                        </span>
                        Lampiran
                        <span v-if="form.category_id == 2 || form.category_id == 5 || form.category_id == 7 || form.category_id == 8"
                          class="error--text">
                          (wajib diisi)
                        </span>

                        <span v-else>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-row>
                        <v-col
                          cols="6">
                          <uploadfolder 
                            v-model="upload.folder">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.file || process.doc"
                                :loading="process.file"
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"
                                class="body-2 font-weight-bold text-capitalize border-radius d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="amanata"
                                    :src="require('@/assets/icon/upload_image.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload Gambar
                                </span>
                              </v-btn>
                            </div>
                          </uploadfolder>
                        </v-col>

                        <v-col
                          cols="6">
                          <uploaddocument 
                            v-model="upload.doc">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.doc || process.file"
                                :loading="process.doc"
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"
                                class="body-2 font-weight-bold text-capitalize border-radius d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="amanata"
                                    :src="require('@/assets/icon/upload_folder.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload PDF
                                </span>
                              </v-btn>
                            </div>
                          </uploaddocument>
                        </v-col>
                      </v-row>

                      <div v-if="form.attachment === ''"
                        class="line-text-second font-italic"
                        style="opacity: 70%;">
                        Upload Gambar atau PDF untuk melengkapi data lampiran dari laporan Anda.
                      </div>

                      <div v-if="form.attachment !== ''"
                        class="mt-2">
                        <div v-if="form.attachment.split('.').pop() !== 'pdf'">
                          <v-img
                            contain
                            alt="amanata" 
                            width="100%"
                            height="auto"
                            :src="form.attachment"
                            class="ma-auto border-radius">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div v-else>
                          <iframe 
                            :src="`${form.attachment}#toolbar=0`" 
                            allowfullscreen
                            frameborder="0" 
                            width="100%"
                            height="397"
                            style="border-radius: 15px; border: 2px solid #323639;">
                          </iframe>
                        </div>
                      </div>

                      <!-- <uploadfolder 
                        v-model="upload.folder">
                        <div 
                          slot="activator" 
                          class="cursor-pointer">
                          <v-card 
                            flat
                            width="100%"
                            height="260"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea; 
                                  border-style: dashed;">
                            <v-img v-if="!process.file && form.attachment !== ''"
                              contain
                              alt="kominfo" 
                              width="100%"
                              height="256"
                              :src="form.attachment"
                              class="ma-auto d-flex align-center text-center">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-icon 
                                size="65" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>
                            </v-img>

                            <v-icon v-if="!process.file && form.attachment === ''"
                              size="65" 
                              color="grey">
                              mdi-cloud-upload-outline
                            </v-icon>

                            <v-progress-circular v-if="process.file"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-card>
                        </div>
                      </uploadfolder> -->
                    </div>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-2 mb-6">
                  <div
                    class="error--text d-flex align-start">
                    <v-icon size="18" color="red" class="mt-1">mdi-alert-circle-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      {{ message.error }}
                    </span>
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="amanata" 
                      :src="require('@/assets/icon/report.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Silahkan isi laporan kegiatan yang Anda lakukan hari ini, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form || (form.attachment == '' && (form.category_id == 2 || form.category_id == 5 || form.category_id == 7 || form.category_id == 8))"
                      :loading="process.form"
                      v-on:keyup.enter="saveReport"
                      @click="saveReport"
                      block
                      large
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize border-radius white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="sheet.add_report = false"
                      :disabled="process.form"
                      block
                      large
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize border-radius red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.detail_report = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Laporan
              </span>
            </v-card-title>
            
            <v-card-text v-if="Object.keys(selected.report).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ selected.report.datetime | date }} Pukul {{ selected.report.datetime | time }} <span v-if="selected.report.end_datetime !==  ''">- {{ selected.report.end_datetime | time }}</span>
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.report.title }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Kategori:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.category_title }}
              </div>

              <div v-if="selected.report.category_id == 7">
                <div
                  class="body-2 font-weight-bold black--text mb-2">
                  Nominal
                </div>

                <div
                  class="body-2 line-text-second mb-6">
                  {{ selected.report.nominal | price }}
                </div>
              </div>
              
              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.desc }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lampiran:
              </div>

              <div
                class="mb-6">
                <!-- <v-img
                  width="100%"
                  height="auto"
                  alt="amanata"
                  class="border-radius"
                  :src="selected.report.attachment">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->

                <div v-if="selected.report.attachment.split('.').pop() !== 'pdf'">
                  <v-img
                    contain
                    alt="amanata" 
                    width="100%"
                    height="auto"
                    :src="selected.report.attachment"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div v-else>
                  <iframe 
                    :src="`${selected.report.attachment}#toolbar=0`" 
                    allowfullscreen
                    frameborder="0" 
                    width="100%"
                    height="397"
                    style="border-radius: 15px; border: 2px solid #323639;">
                  </iframe>
                </div>
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lokasi:
              </div>

              <div>
                <gmap-map
                  ref="gmap"
                  :center="center"
                  :zoom="15"
                  :scrollwheel="false"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  style="width:100%; height: 30vh;">
                  <gmap-marker
                    :position="currentPosition.latlng"
                    :icon="currentPosition.icon">
                  </gmap-marker>
                </gmap-map>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col 
                    cols="12" 
                    class="py-0">
                    <v-btn
                      @click="sheet.add_report = true; reset_report(selected.report);"
                      block
                      large
                      color="blue"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize border-radius white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-circle-edit-outline</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Ubah
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.camera_attendance"
          hide-overlay
          persistent>
          <uploadcamera v-if="selected.type_attendance === 'camera'"
            :sheet="sheet.camera_attendance"
            @frame="sheet.camera_attendance = $event"
            @input="addCamera($event)"
            @close="sheet.camera_attendance = $event; selected.type_attendance = '';" />
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        datenow: new Date(),
        sheet: {
          add_report: false,
          detail_report: false,
          camera_attendance: false
        },
        selected: {
          report: {},
          type_attendance: ''
        },
        dialog: {
          form: false,
          success: false,
          option: false,

          permission: false,

          alasan: false
        },
        search: '',
        center: {
          lat: null,
          lng: null
        },
        currentPosition: {},
        detail: {},
        category: [],
        list: {
          report: []
        },
        pagination: {
          report: {}
        },
        upload: {
          folder: null,
          doc: null
        },

        picker: {
          pelaksanaan: false
        },

        time: {
          pelaksanaan: false,
          selesai: false
        },

        form: {
          id: '',
          start_datetime: '',
          end_datetime: '',
          latitude: '',
          longitude: '',
          type: '',
          start_image_url: '',
          end_image_url: '',
          
          // REPORT
          title: '',
          desc: '',
          attachment: '',

          category_id: '',

          nominal: 0,

          pelaksanaan_date: '',
          pelaksanaan_time: '',
          datetime: '',

          selesai_date: '',
          selesai_time: '',
          end_datetime: ''
        },
        process: {
          attendance: false,
          report: false,
          form: false,
          file: false,
          doc: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda Amanata',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      uploadcamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      uploaddocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.attachment = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },
      'upload.doc': {
        handler: function () {
          if (this.upload.doc) {
            this.process.doc = this.upload.doc.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD DOC

              this.form.attachment = this.upload.doc.fileuri
            }
          }
        },
        deep: true
      },
      'selected.report': function(val) {
        this.$gmapApiPromiseLazy().then(() => {
          this.center = {
            lat: parseFloat(val.latitude),
            lng: parseFloat(val.longitude)
          }

          this.currentPosition = {
            latlng: {
              lat: parseFloat(val.latitude),
              lng: parseFloat(val.longitude)
            },
            icon: {
              url: require('@/assets/image/location_pin.png'),
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(25, 50)
            }
          }
        })
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      popup () {
        return JSON.parse(this.$store.state.popup.popup)
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.05)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchLastAttendance()
        this.fetchReport()
      },
      async fetchLastAttendance () {
        this.process.attendance = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user/attendance/last`)
        .then((response) => {
          this.process.attendance = false

          if (response.status === 200) {
            this.detail = response.results.data.data.length > 0 ? response.results.data.data[0] : {}
          }
        })
      },
      async fetchReport () {
        this.process.report = true

        let params = {
          limit: 3,
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user/report`, { params })
        .then((response) => {
          this.process.report = false

          if (response.status === 200) {
            this.list.report = response.results.data
            this.pagination.report = response.results.pagination

            if (this.sheet.detail_report) {
              this.list.report.map(obj => {
                if (this.selected.report.id === obj.id) this.selected.report = obj
              })
            }
          }
        })
      },
      async fetchCategory () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/admin/task_category/data`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.category = response.results.data
          }
        })
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              self.form = {
                id: Object.keys(self.detail).length > 0 ? self.detail.id : '',
                start_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                end_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                type: item !== undefined ? item : '',
                start_image_url: '',
                end_image_url: '',

                // REPORT
                title: '',
                desc: '',
                attachment: '',

                category_id: '',

                nominal: 0,

                pelaksanaan_date: '',
                pelaksanaan_time: '',
                datetime: '',

                selesai_date: '',
                selesai_time: '',
                end_datetime: ''
              }
            })

            if (item !== undefined) self.dialog.form = true

            if (self.sheet.add_report) self.fetchCategory()
          } else {
            if (item !== undefined) self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''
      },

      reset_report (item) {
        if (this.$refs.form) this.$refs.form.reset()

        navigator.geolocation.getCurrentPosition(position => {
          this.form = {
            id: item !== undefined ? item.id : '',
        
            title: item !== undefined ? item.title : '',
            desc: item !== undefined ? item.desc : '',
            latitude: item !== undefined && item.latitude !== '' ? item.latitude : position.coords.latitude,
            longitude: item !== undefined && item.longitude !== '' ? item.longitude : position.coords.longitude,
            attachment: item !== undefined ? item.attachment : '',

            category_id: item !== undefined ? item.category_id : '',

            nominal: item !== undefined ? item.nominal : 0,

            pelaksanaan_date: item !== undefined ? item.datetime.split(' ')[0] : '',
            pelaksanaan_time: item !== undefined ? item.datetime.split(' ')[1] : '',
            datetime: item !== undefined ? item.datetime : '',

            selesai_date: item !== undefined ? item.end_datetime.split(' ')[0] : '',
            selesai_time: item !== undefined ? item.end_datetime.split(' ')[1] : '',
            end_datetime: item !== undefined ? item.end_datetime : ''
          }
        })

        if (this.sheet.add_report) this.fetchCategory()

        this.process.form = false

        this.message.error = ''
      },

      addCamera (e) {
        this.process.form = e.process

        if (!e.process) {
          if (this.form.type === 'datang') {
            this.form.start_image_url = e.fileuri
          } else {
            this.form.end_image_url = e.fileuri
          }

          this.sheet.camera_attendance = false

          this.saveCheckInOut()
        } 
      },
      async saveCheckInOut () {
        this.message.error = ''

        this.process.form = true

        let url = this.form.type === 'datang' ? 'user/attendance/checkin' : 'user/attendance/checkout'

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/${url}`, this.form)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.option = false

            this.dialog.success = true

            this.fetchLastAttendance()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },
      async saveReport () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.datetime = `${this.form.pelaksanaan_date} ${this.form.pelaksanaan_time}`
          this.form.end_datetime = `${this.form.pelaksanaan_date} ${this.form.selesai_time}`

          let url = this.form.id === '' ? '/user/report/add' : '/admin/report/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.add_report = false
              
              this.fetchReport()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      closePopup () {
        Cookie.set('popup', false)

        this.$store.state.popup.popup = false
      }
    }
  }
</script>