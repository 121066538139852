<template>
    <div>
      <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
        style="margin-bottom: 16px !important;">
        <v-img
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->

      <!-- <div
        :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
        <v-card-text
          class="black--text py-2">
          <div class="text-center body-1 font-weight-bold mb-5" style="text-align: center; font-weight: bold; margin-bottom: 20px;">
            MEMO
          </div>
          <v-row>
            <v-col
              cols="12"
              md="7">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  UNIT BISNIS
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  TANGGAL
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  :
                  <span v-if="selected.preview.template_meta_json_object.tanggal !== '' && selected.preview.template_meta_json_object.tanggal !== null">
                    {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                  </span>
                  <span v-else>
                    ___________________________
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  LAMP
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>

          <div class="d-flex" style="display: flex;">
            <div class="pr-2" style="padding-right: 8px;">
              Perihal
            </div>
            <div>
              : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
            </div>
          </div>

          <div
            class="mt-8"
            style="margin-top: 8px !important;">

            <div
              class="my-4"
              style="margin-top: 16px !important; margin-bottom: 16px !important;">
              <div
                class=""
                style="margin-bottom: 16px !important;">
                Mohon dilakukan Payroll Gaji Karyawan bulan
                <span v-if="selected.preview.template_meta_json_object.bulan !== '' && selected.preview.template_meta_json_object.bulan !== null">
                  {{ selected.preview.template_meta_json_object.bulan | fullmonth }}
                </span>
                <span v-else>
                  ____________
                </span>
                dengan rincian sebagai berikut :
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important;">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Unit Bisnis
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Jumlah Karyawan
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.jumlah_karyawan !== '' ? selected.preview.template_meta_json_object.jumlah_karyawan : '__________' }}</span>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Rekening Debet
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.no_rekening !== '' ? selected.preview.template_meta_json_object.no_rekening : '__________' }}</span>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Nama Rekening Debet
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.nama_rekening !== '' ? selected.preview.template_meta_json_object.nama_rekening : '__________' }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Total Nominal Transaksi
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">
                      Rp.
                      <span v-if="selected.preview.template_meta_json_object.total_transaksi !== '' && selected.preview.template_meta_json_object.total_transaksi !== null">
                        {{ selected.preview.template_meta_json_object.total_transaksi | number }}
                      </span>
                      <span v-else>
                        ___________________________
                      </span>
                    </span>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Catatan
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.catatan !== '' ? selected.preview.template_meta_json_object.catatan : '__________' }}</span>
                  </v-col>
                </v-row>
              </div>

              <div
                class="mb-1" style="margin-bottom: 2px;;">
                Demikian, terima kasih
              </div>
            </div>

          </div>

          <v-row
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Manager Unit Bisnis
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_manager !== '' ? selected.preview.template_meta_json_object.nama_manager : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Direktur Utama PT UMB
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_direktur !== '' ? selected.preview.template_meta_json_object.nama_direktur : '__________' }}
              </div>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Bendahara Unit Bisnis
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_bendahara_unit !== '' ? selected.preview.template_meta_json_object.nama_bendahara_unit : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Bendahara PT UMB
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_bendaara_pt !== '' ? selected.preview.template_meta_json_object.nama_bendaara_pt : '__________' }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      <!-- </div> -->

      <!-- <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
        style="margin-top: 16px !important;">
        <v-img
          style="position: absolute; bottom: 0;"
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image_footer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->
    </div>
  </template>

  <script>
    import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
    export default {
      props: {
        form: {
          type: Object,
          default: {}
        },
        selected: {
          type: Object,
          default: {}
        },
        // units: {
        //   type: Array,
        //   default: []
        // },
        process: {
          type: Object,
          default: {}
        }
      },
      data () {
        return {
          picker: {
            expired: false,
            tanggal: false,
            tanggal_mulai: false,
            tanggal_selesai: false,
            tanggal_masuk: false,

            range: false
          },

          time: {
            pukul: false
          },
        }
      },
      components: {

      },
      watch: {

      },
      computed: {
        user () {
          return this.$store.state.user
        },

        set_color () {
          return this.$store.state.color
        },
        set_color_rgb_soft () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },

        configHeaders () {
          return this.headers.filter(header => !header.hide)
        },

        // formattedDate: {
        //   get () {
        //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
        //   },
        //   set (newValue) {
        //     if (newValue && newValue.length == 10) {
        //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
        //         this.selected.preview.template_meta_json_object.tanggal = newDate
        //     }
        //   }
        // },

        date_range () {
          return this.filter.dates.join(' s/d ')
        }
      },
      mounted () {

      },
      methods: {

      }
    }
  </script>
