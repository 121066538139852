<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="pb-1 d-flex align-center">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <div
                class="d-flex align-center ml-4">
                <v-btn
                  @click="sheet.bar_qr = true"
                  color="#f0f0f0"
                  elevation="0"
                  min-width="48"
                  min-height="48"
                  class="text-capitalize px-0"

                  style="border-radius: 10px;">
                  <v-icon>
                    mdi-barcode
                  </v-icon>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text>
              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 7 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.preview = item; sheet.preview = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 10px;
                                background: #FFFFFF40;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-title
                              class="d-flex align-center">
                              <div
                                class="body-1 font-weight-bold">
                                No. {{ item.pengadaan_number }}
                              </div>

                              <v-spacer />

                              <div
                                class="body-2">
                                {{ item.pengadaan_date | date }}
                              </div>
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-2 line-text-first">
                              <v-divider
                                class="mt-2 mb-3"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center mb-1">
                                Kode Item: 

                                <v-spacer />

                                {{ item.item_code }}
                              </div>

                              <div
                                class="d-flex align-center">
                                Nama Item: 

                                <v-spacer />

                                {{ item.item_name }}
                              </div>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle 
                              class="body-2 line-text-first">
                              <v-divider
                                class="mt-2 mb-3"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center mb-1">
                                Lokasi: 

                                <v-spacer />

                                {{ item.location_name }}
                              </div>

                              <div
                                class="d-flex align-center mb-1">
                                Qty: 

                                <v-spacer />

                                {{ item.item_qty }}
                              </div>

                              <div
                                class="d-flex align-center mb-1">
                                Satuan: 

                                <v-spacer />

                                {{ item.item_unit }}
                              </div>

                              <div
                                class="d-flex align-center">
                                Kondisi: 

                                <v-spacer />

                                {{ item.condition_name }}
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  style="border-radius: 8px;"
                  :style="`color: ${set_color};`">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="py-16 my-16"
                size="125"
                message="Arsip" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.preview"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.preview = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <div
                class="d-flex align-center">
                <v-icon 
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize ml-1 white--text one-line">
                  <!-- Barcode / QR Kode -->
                  Barcode
                </span>
              </div>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.preview).length > 0"
              class="pa-4 pt-16">
              <!-- <v-tabs 
                v-model="selected.menu"
                flat
                grow
                slider-size="5"
                :color="set_color"
                class="mb-6">
                <v-tab
                  class="d-flex justify-center"
                  v-for="(item, index) in menu"
                  :key="index"
                  style="min-width: 70px;">
                  <v-icon size="20" left>
                    {{ item.icon }}
                  </v-icon>
                  
                  <span 
                    class="body-2 text-capitalize"
                    :class="selected.menu === index ? 'font-weight-bold' : ''">
                    {{ item.text }}
                  </span>
                </v-tab>
              </v-tabs> -->

              <div
                style="min-height: 500px;">
                <div v-if="selected.menu === 0"
                  class="d-flex justify-center"
                  style="padding: 250px 0px;">
                  <barcode 
                    :value="selected.preview.item_barcode"
                    width="2"
                    height="150">
                  </barcode>
                </div>

                <div v-if="selected.menu === 1"
                  class="d-flex justify-center"
                  style="padding: 200px 0px;">
                  <qr-code 
                    id="download_qr"
                    :text="selected.preview.item_qrcode"
                    :size="rps !== 'lg' ? 275 : 500">
                  </qr-code>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.bar_qr"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.bar_qr = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <div
                class="d-flex align-center">
                <v-icon 
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize ml-1 white--text one-line">
                  <!-- Scan Barcode / QR Kode -->
                  Scan Barcode
                </span>
              </div>
            </v-card-title>

            <v-card-text 
              class="pa-0">
              <BarQR v-if="sheet.bar_qr"
                @code="form.code = $event"
                @close="sheet.bar_qr = $event" />
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_item"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.detail_item = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <div v-if="Object.keys(detail_item).length > 0"
                class="d-flex align-center">
                <v-icon 
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize ml-1 white--text one-line">
                  {{ detail_item.item_name }}
                </span>
              </div>
            </v-card-title>

            <v-card-text
              class="pa-4 pt-16">
              <v-skeleton-loader
                :loading="process.load"
                type="article, list-item-three-line, article, list-item-three-line, image">
                <div>
                  <div v-if="!process.load && Object.keys(detail_item).length > 0">
                    <div
                      class="d-flex align-center">
                      <div
                        class="body-1 font-weight-bold black--text mt-2">
                        No Pengadaan. {{ detail_item.pengadaan_number }}
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail_item.pengadaan_date | date }}
                      </div>
                    </div>

                    <div>
                      <v-divider
                        class="mt-2 mb-3"
                        style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                        
                      <div
                        class="d-flex align-center mb-1">
                        Kode Item: 

                        <v-spacer />

                        {{ detail_item.item_code }}
                      </div>

                      <div
                        class="d-flex align-center">
                        Nama Item: 

                        <v-spacer />

                        {{ detail_item.item_name }}
                      </div>
                    </div>

                    <div>
                      <v-divider
                        class="mt-2 mb-3"
                        style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                        
                      <div
                        class="d-flex align-center mb-1">
                        Lokasi: 

                        <v-spacer />

                        {{ detail_item.location_name }}
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        Qty: 

                        <v-spacer />

                        {{ detail_item.item_qty }}
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        Satuan: 

                        <v-spacer />

                        {{ detail_item.item_unit }}
                      </div>

                      <div
                        class="d-flex align-center">
                        Kondisi: 

                        <v-spacer />

                        {{ detail_item.condition_name }}
                      </div>
                    </div>

                    <div>
                      <v-divider
                        class="mt-2 mb-3"
                        style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Barcode
                      </div>

                      <div 
                        class="d-flex justify-center mb-8">
                        <barcode 
                          :value="detail_item.item_barcode"
                          width="2"
                          height="150">
                        </barcode>
                      </div>

                      <!-- <div
                        class="body-1 font-weight-bold black--text text-center mb-2">
                        QR Kode
                      </div>

                      <div 
                        class="d-flex justify-center">
                        <qr-code 
                          id="download_qr"
                          :text="detail_item.item_qrcode"
                          :size="rps !== 'lg' ? 275 : 500">
                        </qr-code>
                      </div> -->
                    </div>
                  </div>
                </div>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          preview: false,

          bar_qr: false,

          detail_item: false
        },

        search: '',

        menu: [
          {
            text: 'Barcode',
            icon: 'mdi-barcode'
          },
          {
            text: 'QR Kode',
            icon: 'mdi-qrcode'
          }
        ],

        selected: {
          preview: {},

          menu: 0
        },

        detail_item: {},

        list: [],
        pagination: {},

        limit: 10,

        form: {
          code: ''
        },

        process: {
          load: false,

          limit: false,

          form: false
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),

      BarQR: () => import(/* webpackPrefetch: true */ '@/components/profile/barqr')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },

      'form.code': function (val) {
        if (this.form.code !== '' || this.form.code !== null || this.form.code !== undefined) {
          this.sheet.detail_item = true

          this.fetchDetail()
        }
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,
          
          'location_id[neq]': 0
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/assets/update_location/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {  
            this.list = response.results.data
            this.pagination = response.results.pagination
          } 
        })
      },

      async fetchDetail () {
        this.process.load = true

        let params = {
          search: this.form.code
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/assets/update_location/data`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {  
            this.detail_item = response.results.data.length > 0 ? response.results.data[0] : {}

            this.form.code = ''
          } 
        })
      }
    }
  }
</script>

<style>
  video {
    height: 400px;
    border-radius: 10px;
  }

  .overlay-element {
    border-radius: 10px;
  }
</style>