<template>
  <div>
    <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
      style="margin-bottom: 16px !important;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>

    <div
      :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
      <v-card-text
        class="black--text py-2">
        <v-row>
          <v-col
            cols="12"
            md="7">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Nomor
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Lamp
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Hal
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0 d-flex align-start"
                style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: flex-start !important;">
                : <span class="ml-1" style="font-weight: 500; margin-left: 4px !important;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-col
            cols="12"
            md="5">
            <div
              class="text-right"
              style="text-align: right !important;">
              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
            </div>
          </v-col> -->
        </v-row>

        <div
          class="mt-10"
          style="margin-top: 40px !important;">
          <v-img
            contain
            width="175"
            height="auto"
            alt="umb"
            :src="require('@/assets/image/assalam.jpeg')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="my-4"
            style="margin-top: 16px !important; margin-bottom: 16px !important;">
            <div
              class="mb-4"
              style="margin-bottom: 16px !important;">
              <div class="mb-1 font-weight-bold" style="margin-bottom: 2px; font-weight: bold;">
                Vendor :
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Nama
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.nama_vendor !== '' ? selected.preview.template_meta_json_object.nama_vendor : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Alamat
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.alamat_vendor !== '' ? selected.preview.template_meta_json_object.alamat_vendor : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Telepon
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.telepon_vendor !== '' ? selected.preview.template_meta_json_object.telepon_vendor : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Email
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.email_vendor !== '' ? selected.preview.template_meta_json_object.email_vendor : '__________' }}
                </v-col>
              </v-row>

              <div class="mb-1 mt-4 font-weight-bold" style="margin-top: 16px; margin-bottom: 2px; font-weight: bold;">
                Dikirim ke :
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Nama
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.nama_kirim !== '' ? selected.preview.template_meta_json_object.nama_kirim : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Alamat
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.alamat_kirim !== '' ? selected.preview.template_meta_json_object.alamat_kirim : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Telepon
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.telepon_kirim !== '' ? selected.preview.template_meta_json_object.telepon_kirim : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Email
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.email_kirim !== '' ? selected.preview.template_meta_json_object.email_kirim : '__________' }}
                </v-col>
              </v-row>
              <div class="mb-1 mt-4 font-weight-bold" style="margin-top: 16px; margin-bottom: 2px; font-weight: bold;">
                Deskripsi Pesanan :
              </div>
              <table style="border-collapse: collapse; width: 100%;">
                <tr>
                  <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                    No
                  </th>
                  <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                    Nama Barang / Jasa
                  </th>
                  <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                    Kuantitas
                  </th>
                  <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                    Harga Satuan
                  </th>
                  <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                    Sub Total
                  </th>
                </tr>

                <tr v-for="(item, index) in selected.preview.template_meta_json_object.item" :key="index">
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: center;">
                    {{ index + 1 }}
                  </td>
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                    {{ item.nama }}
                  </td>
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">

                    <span v-if="item.qty !== '' && item.qty !== null">
                      {{ item.qty | number }}
                    </span>
                    <span v-else>

                    </span>
                  </td>
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">

                    <span v-if="item.harga !== '' && item.harga !== null">
                      Rp. {{ item.harga | number }}
                    </span>
                    <span v-else>

                    </span>
                  </td>
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">
                    Rp.
                    <span v-if="item.harga !== '' && item.harga !== null && item.qty !== '' && item.qty !== null">
                      {{ item.qty*item.harga | number }}
                    </span>
                    <span v-else>

                    </span>
                  </td>
                </tr>

                <tr>
                  <td colspan="4" style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                    Total
                  </td>
                  <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                    <!-- Rp. {{ selected.preview.template_meta_json_object.item.reduce((acc, item) => formatPrice(((item.qty*item.harga*1)++)), 0) }} -->
                    Rp. {{
                      formatPrice(selected.preview.template_meta_json_object.item.map(item => item.qty*item.harga).reduce((acc, item) => acc + item, 0))
                      }}
                  </td>
                </tr>

              </table>
            </div>

            <div
              class="mb-4"
              style="margin-bottom: 16px !important;">

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Term Pembayaran
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.term !== '' ? selected.preview.template_meta_json_object.term : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Pengiriman
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0 d-flex align-start"
                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; lign-items: flex-start !important;">
                  : {{ selected.preview.template_meta_json_object.pengiriman !== '' ? selected.preview.template_meta_json_object.pengiriman : '__________' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Tanggal Pengiriman
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  :
                  <span v-if="selected.preview.template_meta_json_object.tanggal_pengiriman !== '' && selected.preview.template_meta_json_object.tanggal_pengiriman !== null">
                    {{ selected.preview.template_meta_json_object.tanggal_pengiriman | fulldate }}
                  </span>

                  <span v-else>
                    __________
                  </span>
                </v-col>
              </v-row>

              <div class="mt-4 mb-1 font-weight-bold" style="margin-top: 16px; margin-bottom: 2px; font-weight: bold;">
                Instruksi Tambahan:
              </div>
              <div>
                <ol>
                  <li v-for="(item, index) in selected.preview.template_meta_json_object.instruksi" :key="index">
                    {{ item.text }}
                  </li>
                </ol>
              </div>
            </div>

            <div
              style="" class="mt-6">
              Demikian purchase order dari kami, apabila ada hal yang sekiranya masih belum jelas dapat menghubungi kontak yang berlaku. Atas perhatian dan kerjasama, kami ucapkan terima kasih.
            </div>
          </div>

          <v-img
            contain
            width="175"
            height="auto"
            alt="umb"
            :src="require('@/assets/image/wassalam.jpeg')">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>

        <v-row
          justify="end"
          class="mt-8"
          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: right !important;">
            <div>
              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
            </div>

            <div
              class="mb-16"
              style="margin-bottom: 64px !important;">
              {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
            </div>

            <div>
              {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    <!-- </div>

    <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
      style="margin-top: 16px !important;">
      <v-img
        style="position: absolute; bottom: 0;"
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image_footer">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      // units: {
      //   type: Array,
      //   default: []
      // },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        picker: {
          expired: false,
          tanggal: false,
          tanggal_mulai: false,
          tanggal_selesai: false,
          tanggal_masuk: false,

          range: false
        },

        time: {
          pukul: false
        },
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      user () {
        return this.$store.state.user
      },

      set_color () {
        return this.$store.state.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },

      configHeaders () {
        return this.headers.filter(header => !header.hide)
      },

      // formattedDate: {
      //   get () {
      //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
      //   },
      //   set (newValue) {
      //     if (newValue && newValue.length == 10) {
      //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
      //         this.selected.preview.template_meta_json_object.tanggal = newDate
      //     }
      //   }
      // },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {

    },
    methods: {
      formatPrice(value) {
        if (isNaN(value)) return "Not a Number"
        let number = ""
        const reverseNumber = value
          .toString()
          .split("")
          .reverse()
          .join("")
        const arrReverseNumber = [...Array(reverseNumber.length).keys()]
        arrReverseNumber.map(index => {
          if (index % 3 === 0) number += reverseNumber.substr(index, 3) + "."
        })

        return `${
          number.split("", number.length - 1)
          .reverse()
          .join("")
        }`
      },
    }
  }
</script>
