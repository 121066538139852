<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold ml-2">
                Scan Berhasil
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah berhasil checkin event.
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn @click="dialog.success = false; $emit('close', false); to_close();"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.failed"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/logo.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold ml-2">
                Scan Gagal
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              {{ message.error }}
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn @click="dialog.failed = false; $emit('close', false); to_close();"
                color="red"
                elevation="0"
                outlined
                class="text-capitalize border-radius red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="transparent">
            <v-card-title 
              @click="$emit('close', false); to_close();"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Scan QR
              </span>
            </v-card-title>

            <v-card-text 
              class="py-8"
              style="margin-bottom: 120px;">
              <div>
                <ValidationObserver 
                  ref="form">
                  <v-form 
                    class="text-left mt-12">
                    <div
                      style="height: 400px;">
                      <div
                        class="body-1 font-weight-bold black--text text-center">
                        Scan QR Kode
                      </div>

                      <v-divider 
                        class="my-2"
                        style="border-top: 1px dashed #e0e0e0;"/>

                      <div
                        class="body-2 text-center mb-6">
                        Silahkan scan gambar qr dibawah ini:
                      </div>

                      <div
                        style="height: 400px; border-radius: 10px; background: #00000040;">
                        <StreamBarcodeReader
                          ref="scanner"
                          @decode="(a, b, c) => onDecode(a, b, c)"
                          @loaded="() => onLoaded()">
                        </StreamBarcodeReader>
                      </div>

                      <v-row 
                        align="center"
                        class="mt-6">
                        <v-col 
                          cols="3">
                          <v-img 
                            contain
                            alt="amanata" 
                            :src="require('@/assets/image/profile/qrscan.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>

                        <v-col 
                          cols="9">
                          <div>
                            <div 
                              class="body-2 line-text-second black--text" 
                              style="opacity: 60%;">
                              Jika data scan qr berhasil, maka Anda dinyatakan sudah cehckin event yang telah di selenggarakan.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { StreamBarcodeReader } from "vue-barcode-reader";
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false,
          failed: false
        },
        form: {
          id: null,
          uuid: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      StreamBarcodeReader
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      
    },
    methods: {
      onDecode(a, b, c) {
        // console.log(a, b, c)

        // if (this.form.id) clearTimeout(this.form.id)

        // this.form.id = setTimeout(() => {
        //   if (this.form.uuid === a) {
        //     this.form.uuid = ''
        //   }
        // }, 10000)

        this.form.uuid = a

        if (this.form.uuid !== '') this.save()
      },
      onLoaded() {
        // console.log('load')

        // console.log(this.$refs.scanner.codeReader.stream)
      },
      to_close () {
        this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) { track.stop() })
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/user/event/checkin`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              
              // this.$emit('close', false)

              this.dialog.success = true

              this.to_close()
            } else {
              this.dialog.failed = true

              this.message.error = response.message

              this.form.uuid = ''
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style>
  video {
    height: 400px;
    border-radius: 10px;
  }

  .overlay-element {
    border-radius: 10px;
  }
</style>