<template>
    <div>
      <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
        style="margin-bottom: 16px !important;">
        <v-img
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->

      <!-- <div
        :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
        <v-card-text
          class="black--text py-2">
          <div class="text-center body-1 font-weight-bold mb-5" style="text-align: center; font-weight: bold; margin-bottom: 20px;">
            Surat Jalan Pesanan
          </div>
          <v-row>
            <v-col cols="12" md="7">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Nama Pemesan
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.nama_pesanan !== '' ? selected.preview.template_meta_json_object.nama_pesanan : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Unit Pemesan
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Tanggal Pengantaran
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  :
                  <span v-if="selected.preview.template_meta_json_object.tanggal_pengantaran !== '' && selected.preview.template_meta_json_object.tanggal_pengantaran !== null">
                    {{ selected.preview.template_meta_json_object.tanggal_pengantaran | weekday }}, {{ selected.preview.template_meta_json_object.tanggal_pengantaran | fulldate }}
                  </span>
                  <span v-else>
                    ___________________________
                  </span>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="5">
              <v-row>
                <v-col
                  cols="12"
                  md="7"
                  class="py-0 px-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Waktu Pengantaran
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.waktu_pengantaran !== '' ? selected.preview.template_meta_json_object.waktu_pengantaran : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="7"
                  class="py-0 px-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Tempat Pengantaran
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.tempat_pengantaran !== '' ? selected.preview.template_meta_json_object.tempat_pengantaran : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="7"
                  class="py-0 px-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  No. Hp / Telp
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.no_telepon !== '' ? selected.preview.template_meta_json_object.no_telepon : '__________' }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div
            class="mt-8"
            style="margin-top: 8px !important;">

            <table style="border-collapse: collapse; width: 100%;">
              <tr>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  No
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Nama Produk
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Detail Produk
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Qty
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Sat
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Harga
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                  Jumlah
                </th>
              </tr>

              <tr v-for="(item, index) in selected.preview.template_meta_json_object.item" :key="index">
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: center;">
                  {{ index + 1 }}
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                  {{ item.nama }}
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                  {{ item.detail }}
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">

                  <span v-if="item.qty !== '' && item.qty !== null">
                    {{ item.qty | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                  {{ item.sat }}
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">

                  <span v-if="item.harga !== '' && item.harga !== null">
                    {{ item.harga | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">
                  Rp.
                  <span v-if="item.harga !== '' && item.harga !== null && item.qty !== '' && item.qty !== null">
                    {{ item.qty*item.harga | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="6" style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                  Total
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">
                  Rp. {{
                        formatPrice(selected.preview.template_meta_json_object.item.map(item => item.qty*item.harga).reduce((acc, item) => acc + item, 0))
                        }}
                </td>
              </tr>

            </table>

            <div
              class="mb-4 mt-2"
              style="margin-bottom: 16px !important; margin-top: 8px;">
              Catatan : {{ selected.preview.template_meta_json_object.catatan !== '' ? selected.preview.template_meta_json_object.catatan : '__________' }}
            </div>

          </div>

          <v-row
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Penerima
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_penerima !== '' ? selected.preview.template_meta_json_object.nama_penerima : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Admin Unit Bisnis
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.nama_admin !== '' ? selected.preview.template_meta_json_object.nama_admin : '__________' }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      <!-- </div> -->

      <!-- <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
        style="margin-top: 16px !important;">
        <v-img
          style="position: absolute; bottom: 0;"
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image_footer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->
    </div>
  </template>

  <script>
    import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
    export default {
      props: {
        form: {
          type: Object,
          default: {}
        },
        selected: {
          type: Object,
          default: {}
        },
        // units: {
        //   type: Array,
        //   default: []
        // },
        process: {
          type: Object,
          default: {}
        }
      },
      data () {
        return {
          picker: {
            expired: false,
            tanggal: false,
            tanggal_mulai: false,
            tanggal_selesai: false,
            tanggal_masuk: false,

            range: false
          },

          time: {
            pukul: false
          },
        }
      },
      components: {

      },
      watch: {

      },
      computed: {
        user () {
          return this.$store.state.user
        },

        set_color () {
          return this.$store.state.color
        },
        set_color_rgb_soft () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },

        configHeaders () {
          return this.headers.filter(header => !header.hide)
        },

        // formattedDate: {
        //   get () {
        //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
        //   },
        //   set (newValue) {
        //     if (newValue && newValue.length == 10) {
        //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
        //         this.selected.preview.template_meta_json_object.tanggal = newDate
        //     }
        //   }
        // },

        date_range () {
          return this.filter.dates.join(' s/d ')
        }
      },
      mounted () {

      },
      methods: {
        formatPrice(value) {
          if (isNaN(value)) return "Not a Number"
          let number = ""
          const reverseNumber = value
            .toString()
            .split("")
            .reverse()
            .join("")
          const arrReverseNumber = [...Array(reverseNumber.length).keys()]
          arrReverseNumber.map(index => {
            if (index % 3 === 0) number += reverseNumber.substr(index, 3) + "."
          })

          return `${
            number.split("", number.length - 1)
            .reverse()
            .join("")
          }`
        },

      }
    }
  </script>
