<template>
    <div>
      <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
        style="margin-bottom: 16px !important;">
        <v-img
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

      <div
        :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
        <v-card-text
          class="black--text py-2" style="font-size: 12px;">
          <div class="text-center body-2 font-weight-bold mb-5" style="text-align: center; font-weight: bold; margin-bottom: 20px;">
            <div>
              PERMOHONAN PENCAIRAN DANA
            </div>
            <div>
              CASHPOOLING KE-{{ selected.preview.template_meta_json_object.urutan === '' ? '____' : selected.preview.template_meta_json_object.urutan }}
            </div>
            <div>
              DARI REKENING UTAMA PT UMB 7447447414
            </div>
          </div>

          <div class="mt-4" style="margin-top: 16px;">
            Berikut dana Cashpooling Unit Bisnis {{ selected.preview.template_meta_json_object.unit === '' ? '____' : selected.preview.template_meta_json_object.unit }} yang telah terverifikasi dari
            <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
              {{ selected.preview.template_meta_json_object.tanggal_mulai | day }}
            </span>

            <span v-else>
              __________
            </span>

            sampai dengan

            <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
              {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }}
            </span>

            <span v-else>
              __________
            </span>
          </div>
          <div
            style="margin-top: 8px !important;">

            <table style="border-collapse: collapse; width: 100%;">
              <tr>
                <th colspan="2" style="font-size: 13px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Dana Masuk di Rekening Utama
                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 1px; white-space: nowrap;">

                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 1px; white-space: nowrap;">

                </th>
                <th style="font-size: 13px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 1px; white-space: nowrap;">

                </th>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Titip Transfer
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.titip !== '' && selected.preview.template_meta_json_object.titip !== null">
                    Rp. {{ selected.preview.template_meta_json_object.titip | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Pengembalian Kasbon
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; min-width: 100px;">
                  <span v-if="selected.preview.template_meta_json_object.pengembalian !== '' && selected.preview.template_meta_json_object.pengembalian !== null">
                    Rp. {{ selected.preview.template_meta_json_object.pengembalian | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; min-width: 100px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; min-width: 100px;">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Pendapatan
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.pendapatan !== '' && selected.preview.template_meta_json_object.pendapatan !== null">
                    Rp. {{ selected.preview.template_meta_json_object.pendapatan | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <th colspan="2" style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Total Dana Masuk di Rekening Utama
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Rp. {{ (selected.preview.template_meta_json_object.pendapatan + selected.preview.template_meta_json_object.pengembalian + selected.preview.template_meta_json_object.titip) | number}}
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </th>
              </tr>

              <tr>
                <td colspan="2" style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; color: white;">
                  Total Dana Masuk di Rekening Utama
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">

                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <th colspan="2" style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Potongan
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">

                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </th>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Penyisihan Pajak (11%)
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.penyisihan !== '' && selected.preview.template_meta_json_object.penyisihan !== null">
                    Rp. {{ selected.preview.template_meta_json_object.penyisihan | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  PPN (11%)
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.ppn !== '' && selected.preview.template_meta_json_object.ppn !== null">
                    Rp. {{ selected.preview.template_meta_json_object.ppn | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  PPH 4 (2)
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.pph !== '' && selected.preview.template_meta_json_object.pph !== null">
                    Rp. {{ selected.preview.template_meta_json_object.pph | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Operasional (5%)
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.operasional !== '' && selected.preview.template_meta_json_object.operasional !== null">
                    Rp. {{ selected.preview.template_meta_json_object.operasional | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: center; width: 50px; max-width: 50px; min-width: 50px;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Pengembalian PPH 23 ke Lawan Transaksi
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  <span v-if="selected.preview.template_meta_json_object.lawan !== '' && selected.preview.template_meta_json_object.lawan !== null">
                    Rp. {{ selected.preview.template_meta_json_object.lawan | number }}
                  </span>
                  <span v-else>

                  </span>
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </td>
                <td style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </td>
              </tr>

              <tr>
                <th colspan="2" style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Total Potongan
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Rp. {{ (selected.preview.template_meta_json_object.penyisihan + selected.preview.template_meta_json_object.ppn + selected.preview.template_meta_json_object.pph + selected.preview.template_meta_json_object.operasional + selected.preview.template_meta_json_object.lawan) | number }}
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </th>
              </tr>

              <tr>
                <th colspan="2" style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Total Dana Yang Dicarikan ke VA Rek Unit Bisnis
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left; ">
                </th>
                <th style="font-size: 12px; border: 1px solid #ddd; padding: 2px 8px; text-align: left;">
                  Rp. {{ (selected.preview.template_meta_json_object.pendapatan + selected.preview.template_meta_json_object.pengembalian + selected.preview.template_meta_json_object.titip) -(selected.preview.template_meta_json_object.penyisihan + selected.preview.template_meta_json_object.ppn + selected.preview.template_meta_json_object.pph + selected.preview.template_meta_json_object.operasional + selected.preview.template_meta_json_object.lawan) | number }}
                </th>
              </tr>

            </table>

            <div
              class="mb-0 mt-3"
              style="margin-bottom: 4px !important; margin-top: 12px;">
              Dari total dana yang masuk di rekening utama akan didistribusikan ke :
            </div>
            <div>
              <ol>
                <li>
                  Rekening VA TAMPUNGAN PAJAK (9738275758100024) sebesar
                  <span style="font-weight: 500;">
                    Rp.
                    <span v-if="selected.preview.template_meta_json_object.nominal_pajak !== '' && selected.preview.template_meta_json_object.nominal_pajak !== null">
                      {{ selected.preview.template_meta_json_object.nominal_pajak | number }}
                    </span>
                    <span v-else>
                      _________________
                    </span>
                  </span>
                </li>
                <li>
                  Rekening OPERASIONAL PT UMB (7575811118) sebesar
                  <span style="font-weight: 500;">
                    Rp.
                    <span v-if="selected.preview.template_meta_json_object.nominal_operasional !== '' && selected.preview.template_meta_json_object.nominal_operasional !== null">
                      {{ selected.preview.template_meta_json_object.nominal_operasional | number }}
                    </span>
                    <span v-else>
                      _________________
                    </span>
                  </span>
                </li>
                <li>
                  Rekening VA CAD {{ selected.preview.template_meta_json_object.unit === '' ? '_____________' : selected.preview.template_meta_json_object.unit }} ({{ selected.preview.template_meta_json_object.no_rekening === '' ? '_____________' : selected.preview.template_meta_json_object.no_rekening }}) sebesar
                  <span style="font-weight: 500;">
                    Rp.
                    <span v-if="selected.preview.template_meta_json_object.nominal_cad !== '' && selected.preview.template_meta_json_object.nominal_cad !== null">
                      {{ selected.preview.template_meta_json_object.nominal_cad | number }}
                    </span>
                    <span v-else>
                      _________________
                    </span>
                  </span>
                </li>
              </ol>
            </div>

          </div>

          <v-row
            class="mt-4"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 18px !important;">
            <v-col cols="12">
              <div class="text-center">
                Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
              </div>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Bendahara
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.bendahara !== '' ? selected.preview.template_meta_json_object.bendahara : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Manager
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.manager !== '' ? selected.preview.template_meta_json_object.manager : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Bendahara PT UMB
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.bendahara_umb !== '' ? selected.preview.template_meta_json_object.bendahara_umb : '__________' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Direktur Utama PT UMB
              </div>

              <div class="mt-16">
                {{ selected.preview.template_meta_json_object.direktur_umb !== '' ? selected.preview.template_meta_json_object.direktur_umb : '__________' }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      <!-- </div>

      <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
        style="margin-top: 16px !important;">
        <v-img
          style="position: absolute; bottom: 0;"
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image_footer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->
    </div>
  </template>

  <script>
    import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
    export default {
      props: {
        form: {
          type: Object,
          default: {}
        },
        selected: {
          type: Object,
          default: {}
        },
        // units: {
        //   type: Array,
        //   default: []
        // },
        process: {
          type: Object,
          default: {}
        }
      },
      data () {
        return {
          picker: {
            expired: false,
            tanggal: false,
            tanggal_mulai: false,
            tanggal_selesai: false,
            tanggal_masuk: false,

            range: false
          },

          time: {
            pukul: false
          },
        }
      },
      components: {

      },
      watch: {

      },
      computed: {
        user () {
          return this.$store.state.user
        },

        set_color () {
          return this.$store.state.color
        },
        set_color_rgb_soft () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },

        configHeaders () {
          return this.headers.filter(header => !header.hide)
        },

        // formattedDate: {
        //   get () {
        //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
        //   },
        //   set (newValue) {
        //     if (newValue && newValue.length == 10) {
        //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
        //         this.selected.preview.template_meta_json_object.tanggal = newDate
        //     }
        //   }
        // },

        date_range () {
          return this.filter.dates.join(' s/d ')
        }
      },
      mounted () {

      },
      methods: {

      }
    }
  </script>
