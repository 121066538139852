<template>
    <div>
      <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
        style="margin-bottom: 16px !important;">
        <v-img
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

      <div
        :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
        <v-card-text
          class="black--text py-2">
          <v-row>
            <v-col
              cols="12"
              md="7">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Nomor
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Lampiran
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Hal
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0 d-flex align-start"
                  style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; align-items: flex-start !important;">
                  : <span class="ml-1" style="font-weight: 500; margin-left: 4px !important;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col
              cols="12"
              md="5">
              <div
                class="text-right"
                style="text-align: right !important;">
                Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
              </div>
            </v-col> -->
          </v-row>

          <div
            class="mt-6"
            style="margin-top: 24px !important;">
            <div
              style="margin-bottom: 1px;">
              Kepada Yth.
            </div>

            <div
              style="margin-bottom: 1px;">
              {{ selected.preview.template_meta_json_object.nama_penerima !== '' ? selected.preview.template_meta_json_object.nama_penerima : '__________' }}
            </div>

            <div
              style="margin-bottom: 1px;">
              di Tempat
            </div>
          </div>

          <div
            class="mt-10"
            style="margin-top: 40px !important;">
            <v-img
              contain
              width="175"
              height="auto"
              alt="umb"
              :src="require('@/assets/image/assalam.jpeg')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <div
              class="my-4"
              style="margin-top: 16px !important; margin-bottom: 16px !important;">
              <div
                class="mb-4"
                style="margin-bottom: 16px !important;">
                Puji syukur kami panjatkan kehadirat Allah SWT yang senantiasa melimpahkan Rahmat, hidayah serta inayahNya kepada kita semua. Sholawat dan salam semoga tetap tercurah kepada junjungan kita Nabi Muhammad SAW. Aamiin.
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important;">
                Sehubungan dengan adanya kegiatan {{ selected.preview.template_meta_json_object.kegiatan !== '' ? selected.preview.template_meta_json_object.kegiatan : '__________' }}, maka {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }} bermaksud mengajukan permohonan sponsorship berupa {{ selected.preview.template_meta_json_object.permohonan !== '' ? selected.preview.template_meta_json_object.permohonan : '__________' }} untuk menunjang kelacaran kegiatan tersebut.
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important;">
                Perlu kami sampaikan, bahwa kegiatan sebagaimana dimaksud insyaAllah diselenggarakan pada :
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important;">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Hari/Tanggal
                  </v-col>

                  <v-col
                    cols="12"
                    md="9"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    :
                    <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                      {{ selected.preview.template_meta_json_object.hari_tanggal | weekday }}
                    </span>

                    <span v-else>
                      __________
                    </span>

                    ,

                    <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
                      {{ selected.preview.template_meta_json_object.hari_tanggal | fulldate }}
                    </span>

                    <span v-else>
                      __________
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Pukul
                  </v-col>

                  <v-col
                    cols="12"
                    md="9"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : {{ selected.preview.template_meta_json_object.pukul !== '' ? selected.preview.template_meta_json_object.pukul : '__________' }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Tempat
                  </v-col>

                  <v-col
                    cols="12"
                    md="9"
                    class="py-0 d-flex align-start"
                    style="padding-top: 0px !important; padding-bottom: 0px !important; display: flex !important; lign-items: flex-start !important;">
                    :
                    <span
                      class="ml-1"
                      style="margin-left: 4px !important;">
                      {{ selected.preview.template_meta_json_object.tempat !== '' ? selected.preview.template_meta_json_object.tempat : '__________' }}
                    </span>
                  </v-col>
                </v-row>
              </div>

              <div
                style="">
                Demikian surat permohonan ini kami sampaikan, terimakasih atas perhatian dan kerjasamanya.
              </div>
            </div>

            <v-img
              contain
              width="175"
              height="auto"
              alt="umb"
              :src="require('@/assets/image/wassalam.jpeg')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <v-row
            justify="end"
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: right !important;">
              <div>
                Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
              </div>

              <div
                class="mb-16"
                style="margin-bottom: 64px !important;">
                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
              </div>

              <div>
                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      <!-- </div>

      <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
        style="margin-top: 16px !important;">
        <v-img
          style="position: absolute; bottom: 0;"
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image_footer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->
    </div>
  </template>

  <script>
    import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
    export default {
      props: {
        form: {
          type: Object,
          default: {}
        },
        selected: {
          type: Object,
          default: {}
        },
        // units: {
        //   type: Array,
        //   default: []
        // },
        process: {
          type: Object,
          default: {}
        }
      },
      data () {
        return {
          picker: {
            expired: false,
            tanggal: false,
            tanggal_mulai: false,
            tanggal_selesai: false,
            tanggal_masuk: false,

            range: false
          },

          time: {
            pukul: false
          },
        }
      },
      components: {

      },
      watch: {

      },
      computed: {
        user () {
          return this.$store.state.user
        },

        set_color () {
          return this.$store.state.color
        },
        set_color_rgb_soft () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },

        configHeaders () {
          return this.headers.filter(header => !header.hide)
        },

        // formattedDate: {
        //   get () {
        //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
        //   },
        //   set (newValue) {
        //     if (newValue && newValue.length == 10) {
        //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
        //         this.selected.preview.template_meta_json_object.tanggal = newDate
        //     }
        //   }
        // },

        date_range () {
          return this.filter.dates.join(' s/d ')
        }
      },
      mounted () {

      },
      methods: {

      }
    }
  </script>
