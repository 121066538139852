<template>
  <div>
    <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
      style="margin-bottom: 16px !important;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->

    <!-- <div
      :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
      <v-card-text
        class="black--text py-2">
        <div class="text-center body-1 font-weight-bold mb-5" style="<v-card-text
          class="black--text py-2">
          <div class="text-center font-weight_bold" style="text-align: center; font-weight: bold;">
            BERITA ACARA
          </div>
          <div class="text-center" style="text-align: center;">
            Nomor: {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
          </div>

          <div class="mt-4" style="margin-bottom: 16px;">
            Pada hari ini,
            <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
              {{ selected.preview.template_meta_json_object.hari_tanggal | weekday }}
            </span>

            <span v-else>
              __________
            </span>

            ,

            <span v-if="selected.preview.template_meta_json_object.hari_tanggal !== '' && selected.preview.template_meta_json_object.hari_tanggal !== null">
              {{ selected.preview.template_meta_json_object.hari_tanggal | fulldate }}
            </span>

            <span v-else>
              __________
            </span>
            , telah dilaksanakan {{ selected.preview.template_meta_json_object.acara !== '' ? selected.preview.template_meta_json_object.acara : '__________' }}, bertempat di {{ selected.preview.template_meta_json_object.tempat !== '' ? selected.preview.template_meta_json_object.tempat : '__________' }}, dengan rincian sebagai berikut:
          </div>

          <div
            class="my-4"
            style="margin-top: 16px !important; margin-bottom: 16px !important;">
            <div
              class="mb-4"
              style="margin-bottom: 16px !important;">
              <div class="mb-1 font-weight-bold" style="font-weight: bold; margin-bottom: 2px">
                1. Pembukaan
              </div>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Waktu
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.pukul !== '' ? selected.preview.template_meta_json_object.pukul : '__________' }}
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Pembukaan oleh
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.pembuka !== '' ? selected.preview.template_meta_json_object.pembuka : '__________' }}
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0 pr-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Keterangan
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.keterangan !== '' ? selected.preview.template_meta_json_object.keterangan : '__________' }}
                </v-col>
              </v-row>

              <div class="mb-1 mt-4 font-weight-bold" style="margin-top: 16px; font-weight: bold; margin-bottom: 2px">
                2. Daftar Hadir
              </div>
              <div>
                <table style="border-collapse: collapse; width: 100%;">
                  <tr>
                    <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                      No
                    </th>
                    <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                      Nama
                    </th>
                    <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                      Jabatan
                    </th>
                    <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; width: 1px; white-space: nowrap;">
                      Perusahaan/Organisasi
                    </th>
                    <th style="font-size: 13px; border: 1px solid #ddd; padding: 8px; text-align: center; min-width: 100px;">
                      Tanda Tangan
                    </th>
                  </tr>

                  <tr v-for="(item, index) in selected.preview.template_meta_json_object.hadir" :key="index">
                    <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: center;">
                      {{ index + 1 }}
                    </td>
                    <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">
                      {{ item.nama }}
                    </td>
                    <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">
                      {{ item.jabatan }}
                    </td>
                    <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left; width: 1px; white-space: nowrap;">
                      {{ item.unit }}
                    </td>
                    <td style="font-size: 12px; border: 1px solid #ddd; padding: 8px; text-align: left;">

                    </td>
                  </tr>
                </table>
              </div>
              <div class="mt-4 mb-1 font-weight-bold" style="margin-top: 16px; font-weight: bold; margin-bottom: 2px">
                3. Agenda Kegiatan
              </div>
              <div style="padding-left: 16px;">
                <ul>
                  <li style="padding-left: 4px;" v-for="(item, index) in selected.preview.template_meta_json_object.agenda" :key="index">
                    {{ item.text }}
                  </li>
                </ul>
              </div>
              <div class="mt-4 mb-1 font-weight-bold" style="margin-top: 16px; font-weight: bold; margin-bottom: 2px">
                4. Pembahasan
              </div>
              <div style="padding-left: 16px;">
                <ul>
                  <li  style="padding-left: 4px;" v-for="(item, index) in selected.preview.template_meta_json_object.pembahasan" :key="index">
                    {{ item.text }}
                    <ul style="padding-left: 26px;">
                      <li  style="padding-left: 4px;">
                        Pembicara: {{ item.pembicara }}
                      </li>
                      <li  style="padding-left: 4px;">
                        Ringkasan: {{ item.ringkasan }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="mt-4 mb-1 font-weight-bold" style="margin-top: 16px; font-weight: bold; margin-bottom: 2px">
                5. Kesimpulan
              </div>
              <div style="padding-left: 16px;">
                <ul>
                  <li style="padding-left: 4px;" v-for="(item, index) in selected.preview.template_meta_json_object.kesimpulan" :key="index">
                    {{ item.text }}
                  </li>
                </ul>
              </div>

              <div class="mb-1 mt-4 font-weight-bold" style="margin-top: 16px; font-weight: bold; margin-bottom: 2px">
                6. Penutup
              </div>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Waktu
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.pukul_tutup !== '' ? selected.preview.template_meta_json_object.pukul_tutup : '__________' }}
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Penutup oleh
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.penutup !== '' ? selected.preview.template_meta_json_object.penutup : '__________' }}
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px;">
                <v-col
                  cols="12"
                  md="3"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Keterangan
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.keterangan_tutup !== '' ? selected.preview.template_meta_json_object.keterangan_tutup : '__________' }}
                </v-col>
              </v-row>
            </div>
          </div>

          <v-row
            justify="end"
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: right !important;">
              <div>
                Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
              </div>

              <div
                class="mb-16"
                style="margin-bottom: 64px !important;">
                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
              </div>

              <div>
                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
              </div>
            </v-col>
          </v-row>

          <div class="mt-4 mb-1 font-weight-bold" style="font-weight: bold; margin-bottom: 2px">
            Tembusan:
          </div>
          <div style="padding-left: 16px;">
            <ul>
              <li v-for="(item, index) in selected.preview.template_meta_json_object.tembusan" :key="index">
                {{ item.text }}
              </li>
            </ul>
          </div>
        </v-card-text>">
          FORM PERMINTAAN PEMBAYARAN LANGSUNG (LS)
        </div>
        <v-row>
          <v-col cols="12" md="7">
            <v-row>
              <v-col
                cols="12"
                md="5"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                NOMOR
              </v-col>

              <v-col
                cols="12"
                md="7"
                class="py-0 pl-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="5"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                UNIT BISNIS
              </v-col>

              <v-col
                cols="12"
                md="7"
                class="py-0 pl-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="5">
            <v-row>
              <v-col
                cols="12"
                md="5"
                class="py-0 pl-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                TANGGAL
              </v-col>

              <v-col
                cols="12"
                md="7"
                class="py-0 pl-0 pr-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                :
                <span v-if="selected.preview.template_meta_json_object.tanggal !== '' && selected.preview.template_meta_json_object.tanggal !== null">
                  {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                </span>
                <span v-else>
                  ___________________________
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div
          class="mt-8"
          style="margin-top: 8px !important;">

          <div
            class=""
            style="margin-bottom: 16px !important;">
            Dengan ini mengajukan pembayaran INVOICE atas transaksi dengan rincian sebagai berikut :

          </div>

          <v-card-text class="py-0">

            <v-row>
              <v-col cols="12" md="7">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pl-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Nama Perusahaan
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0 pl-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : {{ selected.preview.template_meta_json_object.nama_perusahaan !== '' ? selected.preview.template_meta_json_object.nama_perusahaan : '__________' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="7">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0 pl-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Nomor Invoice
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                    class="py-0 pl-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    : {{ selected.preview.template_meta_json_object.no_invoice !== '' ? selected.preview.template_meta_json_object.no_invoice : '__________' }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="5">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0 pl-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    Tanggal Invoice
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="py-0 pl-0 pr-0"
                    style="padding-top: 0px !important; padding-bottom: 0px !important;">
                    <span v-if="selected.preview.template_meta_json_object.tanggal_invoice !== '' && selected.preview.template_meta_json_object.tanggal_invoice !== null">
                    : {{ selected.preview.template_meta_json_object.tanggal_invoice | fulldate }}
                    </span>

                    <span v-else>
                      __________
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <div
            class=""
            style="margin-bottom: 16px !important; margin-top: 16px !important;">
            Mohon untuk mentransaksikan pembayaran dengan keterangan sebagai berikut :
          </div>

          <v-card-text class="py-0">
            <v-row>
              <v-col cols="12" md="4" style="border: 1px solid #000;" class="pa-0">
                <div class="pa-2 text-center">
                  Bank Tujuan
                </div>
                <hr />
                <div class="px-2 py-3 text-center">
                  {{ selected.preview.template_meta_json_object.nama_bank !== '' ? selected.preview.template_meta_json_object.nama_bank : '__________' }}
                </div>
              </v-col>
              <v-col cols="12" md="4" style="border: 1px solid #000; border-left: none;" class="pa-0">
                <div class="pa-2 text-center">
                  Nomor Rekening
                </div>
                <hr />
                <div class="px-2 py-3 text-center">
                  {{ selected.preview.template_meta_json_object.no_rekening !== '' ? selected.preview.template_meta_json_object.no_rekening : '__________' }}
                </div>
              </v-col>
              <v-col cols="12" md="4" style="border: 1px solid #000; border-left: none;" class="pa-0">
                <div class="pa-2 text-center">
                  Nama Rekening
                </div>
                <hr />
                <div class="px-2 py-3 text-center">
                  {{ selected.preview.template_meta_json_object.nama_rekening !== '' ? selected.preview.template_meta_json_object.nama_rekening : '__________' }}
                </div>
              </v-col>
              <v-col cols="12" md="12" style="border: 1px solid #000; border-top: none; border-left: 1px solid #000;" class="pa-0">
                <div class="pa-2 text-lrft">
                  Nominal transaksi : Rp.
                  <span v-if="selected.preview.template_meta_json_object.total_transaksi !== '' && selected.preview.template_meta_json_object.total_transaksi !== null">
                    {{ selected.preview.template_meta_json_object.total_transaksi | number }}
                  </span>
                  <span v-else>
                    ___________________________
                  </span>
                </div>
              </v-col>
              <v-col cols="12" md="12" style="border: 1px solid #000; border-top: none;" class="pa-0">
                <div class="pa-2 text-lrft">
                  Berita transaksi : {{ selected.preview.template_meta_json_object.berita_transaksi !== '' ? selected.preview.template_meta_json_object.berita_transaksi : '__________' }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>

        </div>

        <v-row
          class="mt-8"
          style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: center !important;">
            <div>
              Manager Unit Bisnis
            </div>

            <div class="mt-16">
              {{ selected.preview.template_meta_json_object.nama_manager !== '' ? selected.preview.template_meta_json_object.nama_manager : '__________' }}
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: center !important;">
            <div>
              Direktur Utama PT UMB
            </div>

            <div class="mt-16">
              {{ selected.preview.template_meta_json_object.nama_direktur !== '' ? selected.preview.template_meta_json_object.nama_direktur : '__________' }}
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: center !important;">
            <div>
              Bendahara Unit Bisnis
            </div>

            <div class="mt-16">
              {{ selected.preview.template_meta_json_object.nama_bendahara_unit !== '' ? selected.preview.template_meta_json_object.nama_bendahara_unit : '__________' }}
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-center"
            style="text-align: center !important;">
            <div>
              Bendahara PT UMB
            </div>

            <div class="mt-16">
              {{ selected.preview.template_meta_json_object.nama_bendaara_pt !== '' ? selected.preview.template_meta_json_object.nama_bendaara_pt : '__________' }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    <!-- </div> -->

    <!-- <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
      style="margin-top: 16px !important;">
      <v-img
        style="position: absolute; bottom: 0;"
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image_footer">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      // units: {
      //   type: Array,
      //   default: []
      // },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        picker: {
          expired: false,
          tanggal: false,
          tanggal_mulai: false,
          tanggal_selesai: false,
          tanggal_masuk: false,

          range: false
        },

        time: {
          pukul: false
        },
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      user () {
        return this.$store.state.user
      },

      set_color () {
        return this.$store.state.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },

      configHeaders () {
        return this.headers.filter(header => !header.hide)
      },

      // formattedDate: {
      //   get () {
      //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
      //   },
      //   set (newValue) {
      //     if (newValue && newValue.length == 10) {
      //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
      //         this.selected.preview.template_meta_json_object.tanggal = newDate
      //     }
      //   }
      // },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
