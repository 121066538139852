<template>
  <div>
    <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
      style="margin-bottom: 16px !important;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->

    <!-- <div
      :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'">
      <v-card-title
        class="d-flex justify-center black--text text-uppercase mb-4"
        style="text-transform: uppercase !important; margin-bottom: 16px !important; display: flex !important; justify-content: center !important; font-size: 1.25rem; font-weight: 500; line-height: 2rem; padding: 16px ">
        {{ selected.preview.template_meta_json_object.judul }}
      </v-card-title> -->

      <v-card-text
        class="black--text">
        <v-row>
          <v-col
            cols="12"
            md="7">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                No.
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Hal
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.judul !== '' ? selected.preview.template_meta_json_object.judul : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Lampiran
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="5">
            <div
              class="text-right"
              style="text-align: right !important;">
              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
            </div>
          </v-col>
        </v-row>

        <div
          class="mt-6"
          style="margin-top: 24px !important;">
          <div
            class="mb-2"
            style="margin-bottom: 8px !important;">
            Yang bertandatangan di bawah ini:
          </div>

          <div
            class="px-8"
            style="padding-right: 32px !important; padding-left: 32px !important;">
            <v-row>
              <v-col
                cols="12"
                md="2"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Nama
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Jabatan
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
              </v-col>
            </v-row>
          </div>
        </div>

        <div
          class="mt-2"
          style="margin-top: 8px !important;">
          <div
            class="mb-2"
            style="margin-bottom: 8px !important;">
            Dengan ini menerangkan bahwa yang bersangkutan di bawah ini:
          </div>

          <div
            class="px-8"
            style="padding-right: 32px !important; padding-left: 32px !important;">
            <v-row>
              <v-col
                cols="12"
                md="2"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Nama
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                : {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                Alamat
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                <div
                  class="d-flex align-start"
                  style="display: flex !important; align-items: flex-start !important;">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1"
                    style="margin-left: 4px !important;">
                    {{ selected.preview.template_meta_json_object.alamat_2 !== '' ? selected.preview.template_meta_json_object.alamat_2 : '__________' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div
          class="mt-8"
          style="margin-top: 32px !important;">
          <div
            class="mb-2"
            style="margin-bottom: 8px !important;">
            Karyawan tersebut benar telah bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }} sejak
            <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
              {{ selected.preview.template_meta_json_object.tanggal_mulai | fulldate }}
            </span>

            <span v-else>
              __________
            </span>
            sampai dengan
            <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
              {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }}
            </span>

            <span v-else>
              __________
            </span>
            Adapun jabatan terakhir saudara adalah sebagai {{ selected.preview.template_meta_json_object.jabatan_2 !== '' ? selected.preview.template_meta_json_object.jabatan_2 : '__________' }}.
          </div>

          <div
            class="mb-2"
            style="margin-bottom: 8px !important;">
            Selama bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}, Saudara telah memberikan dedikasi dan loyalitasnya yang besar terhadap perusahaan serta tak pernah sekalipun melakukan hal – hal yang merugikan perusahaan. Dalam hal ini saudara {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }} mengundurkan diri karena {{ selected.preview.template_meta_json_object.alasan !== '' ? selected.preview.template_meta_json_object.alasan : '__________' }}.
          </div>

          <div
            class="mb-2"
            style="margin-bottom: 8px !important;">
            Kami sangat berterimakasih atas kerja kerasnya selama ini dan mengharapkan saudara dapat sukses pada masa mendatang.
          </div>

          <div>
            Demikian surat paklaring ini kami buat agar dapat dipergunakan dengan semestinya. Atas perhatiannya, kami ucapkan terimakasih.
          </div>

          <v-row
            justify="end"
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: center !important;">
              <div>
                Hormat Kami,
              </div>

              <div
                class="mb-16"
                style="margin-bottom: 64px !important;">
                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
              </div>

              <div>
                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    <!-- </div> -->

    <!-- <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
      style="margin-top: 16px !important;">
      <v-img
        style="position: absolute; bottom: 0;"
        contain
        width="100%"
        height="auto"
        alt="umb"
        :src="form.kop_surat_url_image_footer">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular
              indeterminate
              :color="set_color">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      // units: {
      //   type: Array,
      //   default: []
      // },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        picker: {
          expired: false,
          tanggal: false,
          tanggal_mulai: false,
          tanggal_selesai: false,
          tanggal_masuk: false,

          range: false
        },

        time: {
          pukul: false
        },
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      user () {
        return this.$store.state.user
      },

      set_color () {
        return this.$store.state.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.2)`
      },

      configHeaders () {
        return this.headers.filter(header => !header.hide)
      },

      // formattedDate: {
      //   get () {
      //     return (this.form.template_meta_json_object.tanggal && this.form.template_meta_json_object.tanggal.length === 10) ? moment(this.form.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.form.template_meta_json_object.tanggal
      //   },
      //   set (newValue) {
      //     if (newValue && newValue.length == 10) {
      //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
      //         this.form.template_meta_json_object.tanggal = newDate
      //     }
      //   }
      // },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
