<template>
    <div>
      <!-- <div v-if="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null"
        style="margin-bottom: 16px !important;">
        <v-img
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

      <div
        :style="form.kop_surat_url_image !== '' && form.kop_surat_url_image !== null ? `padding: 0px 38px 0px 38px;` : 'padding: 38px;'"> -->
        <v-card-text
          class="black--text py-2">
          <v-row>
            <v-col
              cols="12"
              md="7">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Nomor
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Perihal
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : <span style="font-weight: 500;">{{ selected.preview.template_meta_json_object.hal !== '' ? selected.preview.template_meta_json_object.hal : '__________' }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  Lam
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="py-0"
                  style="padding-top: 0px !important; padding-bottom: 0px !important;">
                  : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div
            class="mt-0"
            style="margin-top: 8px !important;">
            <div
              style="margin-bottom: 1px;">
              Kepada Yth,
            </div>

            <div class="my-3" style="margin-top: 12px; margin-bottom: 12px;">
              {{ selected.preview.template_meta_json_object.nama_penerima !== '' ? selected.preview.template_meta_json_object.nama_penerima : '__________' }}
            </div>

            <div
              style="margin-bottom: 1px;">
              Di Tempat
            </div>

          </div>


          <div class="mt-4 px-3" style="padding-left: 12px; margin-top: 16px;">
            <v-img
              contain
              width="175"
              height="auto"
              alt="umb"
              :src="require('@/assets/image/assalam.jpeg')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <div
            class="mt-8"
            style="margin-top: 8px !important;">

            <div
              style="margin-bottom: 1px; text-indent: 30px;">
              Puji syukur marilah kita panjatkan kehadirat Allah SWT yang telah memberikan nikmat iman dan islam kepada kita sampai sekarang ini. Tak lupa shalawat serta salam semoga selalu tercurah kepada Nabi Muhammad SAW.
            </div>

            <div
              class="my-4"
              style="margin-top: 16px !important; margin-bottom: 16px !important;">
              <div
                class=""
                style="margin-bottom: 16px !important; text-indent: 30px;">
                Bersamaan dengan surat ini kami sampaikan bahwa invoice {{ selected.preview.template_meta_json_object.jenis_tagihan !== '' ? selected.preview.template_meta_json_object.jenis_tagihan : '__________' }} dengan rincian (terlampir) ;
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important; text-indent: 30px;">
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="font-weight-bold" style="font-weight: bold;">
                      Total Tagihan
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="text-align: right;">
                    <div class="font-weight-bold pr-5" style="font-weight: bold; padding-right: 20px;">
                      Rp.
                      <span v-if="selected.preview.template_meta_json_object.total_tagihan !== '' && selected.preview.template_meta_json_object.total_tagihan !== null">
                        {{ selected.preview.template_meta_json_object.total_tagihan | number }}
                      </span>
                      <span v-else>
                        ___________________________
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div
                class="mb-4"
                style="margin-bottom: 16px !important; text-indent: 30px;">
                Pembayaran dapat dilakukan dengan transfer ke {{ selected.preview.template_meta_json_object.rekening_unit !== '' ? selected.preview.template_meta_json_object.rekening_unit : '__________' }}. Demikian surat penagihan ini kami sampaikan. Atas perhatian kami ucapkan terima kasih.
              </div>
            </div>

            <div class="px-3" style="padding-left: 12px; margin-bottom: 16px !important;">
              <v-img
                contain
                width="175"
                height="auto"
                alt="umb"
                :src="require('@/assets/image/wassalam.jpeg')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <div
              class="mb-4"
              style="margin-bottom: 16px !important;">
              Catatan : {{ selected.preview.template_meta_json_object.catatan !== '' ? selected.preview.template_meta_json_object.catatan : '__________' }}
            </div>

          </div>

          <v-row
            justify="end"
            class="mt-8"
            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
            <v-col
              cols="12"
              md="6"
              class="text-center"
              style="text-align: right !important;">
              <div>
                Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
              </div>

              <div
                class="mb-16"
                style="margin-bottom: 64px !important;">
                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
              </div>

              <div>
                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      <!-- </div>

      <div v-if="form.kop_surat_url_image_footer !== '' && form.kop_surat_url_image_footer !== null"
        style="margin-top: 16px !important;">
        <v-img
          style="position: absolute; bottom: 0;"
          contain
          width="100%"
          height="auto"
          alt="umb"
          :src="form.kop_surat_url_image_footer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->
    </div>
  </template>

  <script>
    import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'
    export default {
      props: {
        form: {
          type: Object,
          default: {}
        },
        selected: {
          type: Object,
          default: {}
        },
        // units: {
        //   type: Array,
        //   default: []
        // },
        process: {
          type: Object,
          default: {}
        }
      },
      data () {
        return {
          picker: {
            expired: false,
            tanggal: false,
            tanggal_mulai: false,
            tanggal_selesai: false,
            tanggal_masuk: false,

            range: false
          },

          time: {
            pukul: false
          },
        }
      },
      components: {

      },
      watch: {

      },
      computed: {
        user () {
          return this.$store.state.user
        },

        set_color () {
          return this.$store.state.color
        },
        set_color_rgb_soft () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.color.match(/\w\w/g).map(x => parseInt(x, 16))

          return `rgba(${r}, ${g}, ${b}, 0.2)`
        },

        configHeaders () {
          return this.headers.filter(header => !header.hide)
        },

        // formattedDate: {
        //   get () {
        //     return (this.selected.preview.template_meta_json_object.tanggal && this.selected.preview.template_meta_json_object.tanggal.length === 10) ? moment(this.selected.preview.template_meta_json_object.tanggal).format('DD MMMM YYYY') : this.selected.preview.template_meta_json_object.tanggal
        //   },
        //   set (newValue) {
        //     if (newValue && newValue.length == 10) {
        //         let newDate = `${newValue.substring(6, 10)}-${newValue.substring(3, 5)}-${newValue.substring(0, 2)}`
        //         this.selected.preview.template_meta_json_object.tanggal = newDate
        //     }
        //   }
        // },

        date_range () {
          return this.filter.dates.join(' s/d ')
        }
      },
      mounted () {

      },
      methods: {

      }
    }
  </script>
