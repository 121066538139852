<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-1 d-flex align-center">
              <div v-if="selected.par_id !== 0"
                class="mr-4">
                <v-btn
                  @click="toBack()"
                  elevation="0"
                  min-width="48"
                  min-height="48"
                  style="border-radius: 10px;"
                  color="#f0f0f0"
                  class="body-2 text-capitalize font-weight-bold d-flex align-center px-0">
                  <v-icon>mdi-arrow-u-left-top-bold</v-icon>
                </v-btn>
              </div>

              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <div
                class="d-flex align-center ml-4">
                <v-btn
                  @click="selected.menu = selected.menu === 'table' ? 'card' : 'table'"

                  color="#f0f0f0"
                  elevation="0"
                  min-width="48"
                  min-height="48"
                  class="text-capitalize px-0"

                  style="border-radius: 10px;">
                  <v-icon>
                    {{ selected.menu === 'table' ? 'mdi-format-list-bulleted' : 'mdi-apps' }}
                  </v-icon>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text>
              <div v-if="process.load || check_data('FOLDER').length > 0"
                class="body-2 font-weight-bold">
                Folder
              </div>

              <v-row v-if="process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in 4"
                  :key="index"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="list-item-three-line">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-row v-if="!process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in list"
                  :key="index"
                  v-show="item.type === 'FOLDER'"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-list v-if="!process.load && list.length > 0"
                    class="pa-0"
                    color="#f0f0f0"
                    style="border-radius: 10px;">
                    <v-list-item
                      @click="toLink(index)"
                      class="px-2">
                      <v-list-item-avatar
                        class="my-1 ml-0 mr-2">
                        <v-icon>mdi-folder</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 text-capitalize"
                          style="font-weight: 500;">
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <!-- <v-list-item-action
                        class="ma-0 ml-2">
                        <v-menu
                          transition="slide-x-transition"
                          bottom
                          right
                          offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"

                              icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list
                            dense>
                            <v-list-item
                              v-for="(item_action, index_action) in menu_action.folder"
                              :key="index_action"
                              @click="toAction(item_action.text, list[index])"
                              class="pl-0 pr-3">
                              <v-list-item-avatar
                                class="ma-0">
                                <v-icon
                                  size="20">
                                  {{ item_action.icon }}
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 text-capitalize">
                                  {{ item_action.text }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action> -->
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <div v-if="process.load || check_data('FILE').length > 0"
                class="body-2 font-weight-bold"
                :class="selected.menu === 'table' ? 'mt-2' : 'mt-6'">
                File
              </div>

              <v-row v-if="process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in 4"
                  :key="index"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    :type="selected.menu === 'table' ? 'image' : 'list-item-three-line'">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-row v-if="!process.load">
                <v-col
                  :cols="selected.menu === 'table' ? 6 : 12"
                  v-for="(item, index) in list"
                  :key="index"
                  v-show="item.type === 'FILE'"
                  :class="selected.menu === 'table' ? '' : 'pb-0'">
                  <v-card v-if="!process.load && list.length > 0"
                    flat
                    color="#f0f0f0"
                    style="border-radius: 10px;">
                    <v-list
                      flat
                      color="transparent"
                      class="pa-0">
                      <v-list-item
                        @click="toLink(index)"
                        class="px-2">
                        <v-list-item-avatar
                          class="my-1 ml-0 mr-2">
                          <v-icon color="red">mdi-file-pdf-box</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 text-capitalize">
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <!-- <v-list-item-action
                          class="ma-0 ml-2">
                          <v-menu
                            transition="slide-x-transition"
                            bottom
                            right
                            offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"

                                icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list
                              dense>
                              <v-list-item
                                v-for="(item_action, index_action) in menu_action.file"
                                :key="index_action"
                                @click="toAction(item_action.text, list[index])"
                                class="pl-0 pr-3">
                                <v-list-item-avatar
                                  class="ma-0">
                                  <v-icon
                                    size="20">
                                    {{ item_action.icon }}
                                  </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title
                                    class="body-2 text-capitalize">
                                    {{ item_action.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>

                    <v-card-text v-if="selected.menu === 'table'"
                      class="pt-0">
                      <v-img
                        height="120"
                        :src="require('@/assets/image/word_default.jpeg')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center"
                :class="selected.menu === 'table' ? '' : 'mt-3'">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  style="border-radius: 8px;"
                  :style="`color: ${set_color};`">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="py-16 my-16"
                size="125"
                message="Arsip" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.preview"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <!-- <v-card-title
              @click="sheet.preview = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <div
                class="d-flex align-center">
                <v-icon
                  color="white">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  class="body-1 font-weight-bold text-capitalize ml-1 white--text one-line">
                  Preview {{ selected.preview.title }}
                </span>
              </div>
            </v-card-title> -->

            <v-card-text v-if="Object.keys(selected.preview).length > 0"
              class="pt-4 px-4 pb-0">
              <div v-if="selected.preview.template_id == 0">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <iframe v-if="selected.preview.url !== ''"
                        :src="`${selected.preview.url}#toolbar=0`"
                        allowfullscreen
                        frameborder="0"
                        width="100%"
                        style="border-radius: 10px; border: 2px solid #323639; height: calc(100vh - 88px);">
                      </iframe>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 1">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <v-card-title
                        class="d-flex justify-center black--text text-uppercase mb-4"
                        style="text-transform: uppercase !important; margin-bottom: 16px !important; display: flex !important; justify-content: center !important; font-size: 1.25rem; font-weight: 500; line-height: 2rem; padding: 16px ">
                        {{ selected.preview.template_meta_json_object.judul }}
                      </v-card-title>

                      <!-- <v-card-text
                        class="black--text">
                        <v-row>
                          <v-col
                            cols="7">
                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                No.
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nomor !== '' ? selected.preview.template_meta_json_object.nomor : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Hal
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.judul !== '' ? selected.preview.template_meta_json_object.judul : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="4"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Lampiran
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.lampiran !== '' ? selected.preview.template_meta_json_object.lampiran : '__________' }}
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            cols="5">
                            <div
                              class="text-right"
                              style="text-align: right !important;">
                              Yogyakarta, {{ selected.preview.template_meta_json_object.tanggal | fulldate }}
                            </div>
                          </v-col>
                        </v-row>

                        <div
                          class="mt-6"
                          style="margin-top: 24px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Yang bertandatangan di bawah ini:
                          </div>

                          <div
                            class="px-8"
                            style="padding-right: 32px !important; padding-left: 32px !important;">
                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nama
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Jabatan
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                              </v-col>
                            </v-row>
                          </div>
                        </div>

                        <div
                          class="mt-2"
                          style="margin-top: 8px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Dengan ini menerangkan bahwa yang bersangkutan di bawah ini:
                          </div>

                          <div
                            class="px-8"
                            style="padding-right: 32px !important; padding-left: 32px !important;">
                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Nama
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                : {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col
                                cols="2"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                Alamat
                              </v-col>

                              <v-col
                                cols="10"
                                class="py-0"
                                style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                <div
                                  class="d-flex align-start"
                                  style="display: flex !important; align-items: flex-start !important;">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="ml-1"
                                    style="margin-left: 4px !important;">
                                    {{ selected.preview.template_meta_json_object.alamat_2 !== '' ? selected.preview.template_meta_json_object.alamat_2 : '__________' }}
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>

                        <div
                          class="mt-8"
                          style="margin-top: 32px !important;">
                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Karyawan tersebut benar telah bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }} sejak
                            <span v-if="selected.preview.template_meta_json_object.tanggal_mulai !== '' && selected.preview.template_meta_json_object.tanggal_mulai !== null">
                              {{ selected.preview.template_meta_json_object.tanggal_mulai | fulldate }}
                            </span>

                            <span v-else>
                              __________
                            </span>
                            sampai dengan
                            <span v-if="selected.preview.template_meta_json_object.tanggal_selesai !== '' && selected.preview.template_meta_json_object.tanggal_selesai !== null">
                              {{ selected.preview.template_meta_json_object.tanggal_selesai | fulldate }}
                            </span>

                            <span v-else>
                              __________
                            </span>
                            Adapun jabatan terakhir saudara adalah sebagai {{ selected.preview.template_meta_json_object.jabatan_2 !== '' ? selected.preview.template_meta_json_object.jabatan_2 : '__________' }}.
                          </div>

                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Selama bekerja di {{ selected.preview.template_meta_json_object.unit !== '' ? selected.preview.template_meta_json_object.unit : '__________' }}, Saudara telah memberikan dedikasi dan loyalitasnya yang besar terhadap perusahaan serta tak pernah sekalipun melakukan hal – hal yang merugikan perusahaan. Dalam hal ini saudara {{ selected.preview.template_meta_json_object.nama_2 !== '' ? selected.preview.template_meta_json_object.nama_2 : '__________' }} mengundurkan diri karena {{ selected.preview.template_meta_json_object.alasan !== '' ? selected.preview.template_meta_json_object.alasan : '__________' }}.
                          </div>

                          <div
                            class="mb-2"
                            style="margin-bottom: 8px !important;">
                            Kami sangat berterimakasih atas kerja kerasnya selama ini dan mengharapkan saudara dapat sukses pada masa mendatang.
                          </div>

                          <div>
                            Demikian surat paklaring ini kami buat agar dapat dipergunakan dengan semestinya. Atas perhatiannya, kami ucapkan terimakasih.
                          </div>

                          <v-row
                            justify="end"
                            class="mt-8"
                            style="display: flex; flex-wrap: wrap; flex: 1 1 auto; justify-content: flex-end !important; margin-top: 32px !important;">
                            <v-col
                              cols="6"
                              class="text-center"
                              style="text-align: center !important;">
                              <div>
                                Hormat Kami,
                              </div>

                              <div
                                class="mb-16"
                                style="margin-bottom: 64px !important;">
                                {{ selected.preview.template_meta_json_object.jabatan_1 !== '' ? selected.preview.template_meta_json_object.jabatan_1 : '__________' }}
                              </div>

                              <div>
                                {{ selected.preview.template_meta_json_object.nama_1 !== '' ? selected.preview.template_meta_json_object.nama_1 : '__________' }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text> -->
                      <paklaring_preview :selected="selected" :form="form" :process="process"></paklaring_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 4">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <pemberitahuan_kewenangan_preview :selected="selected" :form="form" :process="process"></pemberitahuan_kewenangan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 5">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <pemberitahuan_libur_preview :selected="selected" :form="form" :process="process"></pemberitahuan_libur_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 3">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <undangan_agenda_lainnya_preview :selected="selected" :form="form" :process="process"></undangan_agenda_lainnya_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 2">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <undangan_pengajian_preview :selected="selected" :form="form" :process="process"></undangan_pengajian_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 6">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <tugas_preview :selected="selected" :form="form" :process="process"></tugas_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 7">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <peringatan_preview :selected="selected" :form="form" :process="process"></peringatan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 8">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <tagihan_preview :selected="selected" :form="form" :process="process"></tagihan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 9">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <pemberitahuan_preview :selected="selected" :form="form" :process="process"></pemberitahuan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 10">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <penawaran_produk_preview :selected="selected" :form="form" :process="process"></penawaran_produk_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 11">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <perintah_kerja_preview :selected="selected" :form="form" :process="process"></perintah_kerja_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 12">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <notulensi_kegiatan_preview :selected="selected" :form="form" :process="process"></notulensi_kegiatan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 13">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <formulir_payroll_gaji_preview :selected="selected" :form="form" :process="process"></formulir_payroll_gaji_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 14">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <formulir_ls_preview :selected="selected" :form="form" :process="process"></formulir_ls_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 15">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <jalan_pesanan_preview :selected="selected" :form="form" :process="process"></jalan_pesanan_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 16">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <permohonan_sponsorship_preview :selected="selected" :form="form" :process="process"></permohonan_sponsorship_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 17">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <permohonan_pembicara_preview :selected="selected" :form="form" :process="process"></permohonan_pembicara_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 18">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <keterangan_kerja_preview :selected="selected" :form="form" :process="process"></keterangan_kerja_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 19">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <pencarian_dana_preview :selected="selected" :form="form" :process="process"></pencarian_dana_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 20">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <pinjam_ruang_preview :selected="selected" :form="form" :process="process"></pinjam_ruang_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 21">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <purchase_order_preview :selected="selected" :form="form" :process="process"></purchase_order_preview>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="selected.preview.template_id == 22">
                <v-card
                  flat
                  height="calc(100vh - 88px)"
                  style="border: 2px solid #f0f0f0; border-radius: 10px; overflow: auto;">
                  <div
                    style="transform: rotate(-90deg);
                          transform-origin: left top;
                          width: calc(100vh - 88px);
                          overflow-x: hidden;
                          position: absolute;
                          top: 100%;
                          left: 0;">
                    <div
                      id="arsippdf"
                      ref="arsippdf">
                      <berita_acara_preview :selected="selected" :form="form" :process="process"></berita_acara_preview>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="sheet.preview = false"
                elevation="0"
                min-width="40"
                min-height="40"
                style="border-radius: 10px;"
                color="#f0f0f0"
                class="body-2 text-capitalize font-weight-bold d-flex align-center px-0 mb-2">
                <v-icon>mdi-arrow-down-thick</v-icon>
              </v-btn>

              <v-spacer/>

              <v-btn
                color="green"
                elevation="0"
                min-width="40"
                min-height="40"
                style="border-radius: 10px;"
                class="body-2 text-capitalize font-weight-bold d-flex align-center px-0 mb-2"
                :disabled="process.form"
                :loading="process.form"
                @click="exportToPDF()">
                <v-icon
                  color="white"
                  style="transform: rotate(-90deg);">
                  mdi-download-circle-outline
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  import html2pdf from 'html2pdf.js'

  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          preview: false
        },

        search: '',

        selected: {
          par_id: 0,
          par_id_before: [],

          preview: {},

          menu: 'table'
        },

        menu_action: {
          folder: [
            {
              text: 'pindah',
              icon: 'mdi-file-move-outline'
            },
            {
              text: 'share',
              icon: 'mdi-share-outline'
            },
            {
              text: 'ubah',
              icon: 'mdi-circle-edit-outline'
            },
            {
              text: 'hapus',
              icon: 'mdi-delete-circle-outline'
            }
          ],
          file: [
            {
              text: 'pindah',
              icon: 'mdi-file-move-outline'
            },
            {
              text: 'share',
              icon: 'mdi-share-outline'
            },
            {
              text: 'download',
              icon: 'mdi-download-outline'
            },
            {
              text: 'ubah',
              icon: 'mdi-circle-edit-outline'
            },
            {
              text: 'hapus',
              icon: 'mdi-delete-circle-outline'
            }
          ]
        },

        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,

          limit: false,

          form: false
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      paklaring_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/paklaring.vue'),
      pemberitahuan_kewenangan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/pemberitahuan-kewenangan.vue'),
      pemberitahuan_libur_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/pemberitahuan-libur.vue'),
      undangan_agenda_lainnya_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/undangan-agenda-lainnya.vue'),
      undangan_pengajian_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/undangan-pengajian.vue'),
      tugas_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/tugas.vue'),
      peringatan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/peringatan.vue'),
      tagihan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/tagihan.vue'),
      pemberitahuan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/pemberitahuan.vue'),
      penawaran_produk_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/penawaran-produk.vue'),
      perintah_kerja_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/perintah-kerja.vue'),
      notulensi_kegiatan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/notulensi-kegiatan.vue'),
      formulir_payroll_gaji_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/formulir-payroll-gaji.vue'),
      formulir_ls_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/formulir-ls.vue'),
      jalan_pesanan_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/jalan-pesanan.vue'),
      permohonan_sponsorship_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/permohonan-sponsorship.vue'),
      permohonan_pembicara_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/permohonan-pembicara.vue'),
      keterangan_kerja_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/keterangan-kerja.vue'),
      pencarian_dana_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/pencarian-dana.vue'),
      pinjam_ruang_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/pinjam-ruang.vue'),
      purchase_order_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/purchase-order.vue'),
      berita_acara_preview: () => import(/* webpackPrefetch: true */ '@/components/template_preview/berita-acara.vue')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,

          par_id: this.selected.par_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/files/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      toLink (index) {
        if (this.list[index].type === 'FOLDER') {
          this.selected.par_id_before.push(this.selected.par_id)

          this.selected.par_id = this.list[index].id

          this.fetch()
        } else {
          this.sheet.preview = true
          this.selected.preview = this.list[index]
        }
      },

      toBack () {
        this.selected.par_id = this.selected.par_id_before.pop()

        this.fetch()
      },

      check_data (type) {
        return this.list.filter((item) => {
          return item.type === type
        })
      },

      exportToPDF () {
        this.process.form = true

        if (this.selected.preview.template_id != 0) {
          let filename = `Amanata-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`

          html2pdf(this.$refs.arsippdf, {
            margin: 0.7,
            filename: filename,
            image: {
              type: 'jpeg',
              quality: 0.98
            },
            html2canvas: {
              dpi: 192,
              letterRendering: true,
              useCORS: true
            },
            jsPDF: {
              unit: 'in',
              format: 'letter',
              orientation: 'portrait'
            }
          })

          setTimeout(() => {
            this.process.form = false

            this.sheet.preview = false
            this.selected.preview = {}
          }, 1000)
        } else {
          window.open(this.selected.preview.url, '_blank')

          this.process.form = false
        }
      }
    }
  }
</script>

<style>
  .from_html p {
    margin-bottom: 0px !important;
  }

  .from_html br {
    display: block !important;
    content: "";
    margin: 10px 0 !important;
  }

  .from_html ul, .from_html ol {
    padding-left: 16px !important;
  }
</style>
